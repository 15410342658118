.errors{
    height: 100%;
    body{
        height: 100%;
        text-align: center;
        padding-top:0px;

        .site-wrapper {
            display: table;
            width: 100%;
            height: 100%;
            min-height: 100%;    
            .site-wrapper-inner {
                display: table-cell;
                vertical-align: middle;
                .cover-container {
                    margin-right: auto;
                    margin-left: auto;
                    .logo{ 
                        height: 100px; 
                        width:100px;  
                        margin:10px auto 20px; 
                        background: url('../img/logo.png') no-repeat; 
                        display: block;
                    }
                }
            }
            h1{
                font-weight: 700;
                text-transform: uppercase;
            }
            .btn{
                text-transform: uppercase;
            }
        }
        .form-search{ 
            width:300px; 
            margin:0 auto;
        }   
        @media (min-width: $screen-sm){
            .form-search{ 
                width:320px; 
            }   

        }

    }
}
