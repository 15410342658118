$realizations-grid-bg: #fff;
$realizations-grid-box-shadow: 0 0 18px 0 rgba(40,20,70,0.1); 
$realizations-grid-box-shadow-hover: 0 0 18px 0 rgba(40,20,70,0.3); 
$realizations-grid-color-title: #fff;
$realizations-grid-color-title-hover: darken($realizations-grid-color-title, 20%);
$realizations-grid-font-size-title: 18px;
$realizations-grid-font-size-btn: 13px;

$realizations-gallery-bg: #fff;
$realizations-gallery-box-shadow: 0 0 18px 0 rgba(40,20,70,0.1); 
$realizations-gallery-box-shadow-hover: 0 0 18px 0 rgba(40,20,70,0.3); 
$realizations-gallery-color-title: $text-color;
$realizations-gallery-color-title-hover: darken($realizations-gallery-color-title, 20%);
$realizations-gallery-font-size-title: 22px;
$realizations-gallery-font-size-btn: 13px;