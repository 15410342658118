.gmap{
    .gmap-map{
        // margin-bottom: 40px;
    }
    .gmap-description{
        .gmap-state{
            margin-top: 20px;
            p{
                font-size: $font-size-h1 - 10;
                text-transform: uppercase;
                margin-bottom: 20px;
                line-height: 1;
            }
            &:first-child{
                margin-top: 0;
            }
        }
        .gmap-items{
            .gmap-item{
                margin-bottom: 20px;
                >p{
                    margin-bottom: 0;
                }
                .gmap-name{
                    text-transform: uppercase;
                    font-size: $font-size-h3 - 8;
                    line-height: 1;
                }
                .gmap-street,
                .gmap-city,
                .gmap-phone,
                .gmap-webpage{
                    font-size: $font-size-base - 2;
                }
            }
        }
    }

    @media (min-width: $screen-sm){
        .gmap-description{
            .gmap-state{
                p{
                    font-size: $font-size-h1;
                }
            }
            .gmap-items{
                .gmap-item{
                    .gmap-name{
                        font-size: $font-size-h3 ;
                    }
                    .gmap-street,
                    .gmap-city,
                    .gmap-phone,
                    .gmap-webpage{
                        font-size: $font-size-base;
                    }
                }
            }
        }
    }
}
.leaflet-popup-content{
    font-weight: 500;
    font-size: $font-size-base - 2;
    line-height: 1.6;
    p{
        &:first-of-type{
            margin-top: 1px solid #000;
        }
    }
    .gmap-info-street,
    .gmap-info-city,
    .gmap-info-phone,
    .gmap-info-webpage
    {
        margin: 0!important;
        font-weight: 300;
        text-transform: initial;
        font-size: $font-size-base - 3;
        line-height: 1.3;
    }
    @media (min-width: $screen-sm){
        font-size: $font-size-base;
        .gmap-info-street,
        .gmap-info-city,
        .gmap-info-phone,
        .gmap-info-webpage
        {
            font-size: $font-size-base - 2;
        }
    }
}