
/* Buttons */
.btn-search-box,
.btn-main{
    @include button-variant(#fff, $main-color, $main-color);
}
.btn-main-outline{
    @include button-wicode-variant($main-color, $text-color, transparent, transparent, rgba($main-color, 0.4), $text-color);
    border-width: 1px;
    border-style: solid;
    border-radius: 0;
    text-align: left;
    opacity: 0.75;
    &:hover, &:focus{
        opacity: 1;
    }
}
.btn-black-outline{
    @include button-wicode-variant(#000, #fff, transparent, #000, #000, #000);
    border-width: 2px;
    border-style: solid;
    border-radius: 0;
}
.btn-white-outline{
    @include button-wicode-variant(#fff, $main-color, transparent, transparent, #fff, $main-color);
    border-width: 2px;
    border-style: solid;
    border-radius: 0;
}
.btn-white-black-outline{
    @include button-wicode-variant(#000, $main-color, #fff, #fff, #000, $main-color);
    border-width: 2px;
    border-style: solid;
    border-radius: 0;
}
.btn-center{
    text-align: center;
}
/* END Buttons */
