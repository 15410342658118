html {
    position: relative;
    min-height: 100%;
}
body{    
    margin-bottom: 265px; 
}
audio, canvas, progress, video {
    display: inline-block;
    vertical-align: baseline;
    width: 100%;
}
.fa-right { margin-right:10px; }
.fa-left { margin-left:10px; }
.no-margin { margin: 0; }

::-moz-selection { background: $main-color; color: #fff;}
::selection { background: $main-color; color: #fff;}

/* Back-to-top */
.back-to-top {
    cursor: pointer; 
    position: fixed; 
    bottom: 15px; 
    right: 15px; 
    display:none; 
    z-index: 1040; 
    padding: 2px;
    font-size: 32px; 
    color:darken(#c4c4c4, 30%); 
    background: transparent;
    box-shadow: none!important;
    &:hover {
        color:#c4c4c4; 
        background: transparent;
    }
}
/* END back-to-top */
.site-logo{
    display: block;
    width: 182px;
    font-size: $navbar-cms-font-size-brand;
    color: $navbar-cms-a;
    span{
        font-weight: 400;
        display: block;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
    }         
}