/**** newsletter ****/
#newsletter_form{
    label{ 
        font-size:11px;
        color:#666;  
        font-weight: normal;
    }
    .has-feedback{
        .form-control-feedback { 
            right: 5px;
            color:#666;
        }
        label ~ .form-control-feedback { top: 35px;}
    }
    .selectpicker.btn-default{ 
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
        color: #666;
        display: block;
        font-size: 11px;
        height: 34px;
        line-height: 1.42857;
        padding: 6px 12px;
        transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
        width: 100%;
    }
}

/**** end newsletter ****/