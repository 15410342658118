$projects-grid-bg: #fff;
$projects-grid-box-shadow: 0 0 18px 0 rgba(40,20,70,0.1); 
$projects-grid-box-shadow-hover: 0 0 18px 0 rgba(40,20,70,0.3); 
$projects-grid-color-title: #fff;
$projects-grid-color-title-hover: darken($projects-grid-color-title, 20%);
$projects-grid-font-size-title: 18px;
$projects-grid-font-size-btn: 13px;

$projects-gallery-bg: #fff;
$projects-gallery-box-shadow: 0 0 18px 0 rgba(40,20,70,0.1); 
$projects-gallery-box-shadow-hover: 0 0 18px 0 rgba(40,20,70,0.3); 
$projects-gallery-color-title: $text-color;
$projects-gallery-color-title-hover: darken($projects-gallery-color-title, 20%);
$projects-gallery-font-size-title: 22px;
$projects-gallery-font-size-btn: 13px;