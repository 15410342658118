.radio label, .checkbox label{
    min-height: 1px;
    padding:0;
    font-size: 12px;
}
.checkbox label:after, 
.radio label:after {
    content: '';
    display: table;
    clear: both;
}

.checkbox .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .25em;
    width: 1.3em;
    height: 1.3em;
    float: left;
}

.radio .cr {
    border-radius: 50%;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    font-size: 1.2em;
    line-height: 0;
    top: 50%;
    left: 0;
    color: #3B434D;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
    display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
    opacity: 0;
    transition: all .3s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
    opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
    opacity: .5;
}

.checkbox-products{
    ul {
        list-style-type:none;
        li{
            margin-top: 6px;
            margin-bottom: 6px;
            position: relative;
            .fa.fa-caret-down{
                position: absolute;
                right: 0;
                top: 0;
                padding: 5px;
                cursor: pointer;
            }
            .checkbox{
                margin: 0;
            }
            .cr{
                margin-right: 5px;
            }
            label{
                font-weight: 400;
                font-size: 13px;
                text-transform: initial;
                &.disabled{
                    cursor: not-allowed;
                    color:#777;
                }
            }
        }
    }
    > ul{
        padding-left: 0;
    }
}
.form-check{
    input[type="checkbox"], input[type="radio"]{
        position: absolute;
        right: 9000px;
    }

    /*Check box*/
    input[type="checkbox"] + .label-text:before{
        content: "\f096";
        font-family: "FontAwesome";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing:antialiased;
        width: 1em;
        display: inline-block;
        margin-right: 5px;
    }

    input[type="checkbox"]:checked + .label-text:before{
        content: "\f14a";
        color: $main-color;
        animation: effect 250ms ease-in;
    }

    input[type="checkbox"]:disabled + .label-text{
        color: #aaa;
    }

    input[type="checkbox"]:disabled + .label-text:before{
        content: "\f0c8";
        color: #ccc;
    }

    /*Radio box*/

    input[type="radio"] + .label-text:before{
        content: "\f10c";
        font-family: "FontAwesome";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing:antialiased;
        width: 1em;
        display: inline-block;
        margin-right: 5px;
    }

    input[type="radio"]:checked + .label-text:before{
        content: "\f192";
        color: $main-color;
        animation: effect 250ms ease-in;
    }

    input[type="radio"]:disabled + .label-text{
        color: #aaa;
    }

    input[type="radio"]:disabled + .label-text:before{
        content: "\f111";
        color: #ccc;
    }

    /*Radio Toggle*/

    .toggle input[type="radio"] + .label-text:before{
        content: "\f204";
        font-family: "FontAwesome";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing:antialiased;
        width: 1em;
        display: inline-block;
        margin-right: 10px;
    }

    .toggle input[type="radio"]:checked + .label-text:before{
        content: "\f205";
        color: $main-color;
        animation: effect 250ms ease-in;
    }

    .toggle input[type="radio"]:disabled + .label-text{
        color: #aaa;
    }

    .toggle input[type="radio"]:disabled + .label-text:before{
        content: "\f204";
        color: #ccc;
    }

}
@keyframes effect{
    0%{transform: scale(0);}
    25%{transform: scale(1.3);}
    75%{transform: scale(1.4);}
    100%{transform: scale(1);}
}