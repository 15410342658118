#sidebar{
    .user-sidebar{
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 20px;
        background-color: #fff;
        padding: 20px;
        -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
        -moz-box-shadow:    0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
        box-shadow:         0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
        .user-avatar{
            img{
                margin: 0 auto;
                border-radius: 50%;
                border: 2px solid #fff;
            }
            .avatar{
                border: 2px solid #fff;
                .initials{
                    top: 26px;
                }
            }

        }
        .user-info{
            .user-name{
                color: #444;
                font-size: 13px;
                padding: 15px 20px 0;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                position: relative;
            }
            .user-email{
                color: #777;
                font-size: 12px;
                line-height: 1.42857;
                padding: 3px 20px;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                position: relative;
            }
        }
    }
}