.navbar-horizontal {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: $navbar-horizontal-bg;
    border-color: $navbar-horizontal-border;
    min-height: 55px;
    font-size: 13px!important;
    -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
    -moz-box-shadow:    0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
    box-shadow:         0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
    z-index: 1031;
    .navbar-brand {
        font-size: 14px;
        line-height: 20px;
        height: 50px;
        padding: 17px 10px;        
        margin: 0!important;
        color: $navbar-horizontal-brand-color;
        &:hover,
            &:focus {
            color: $navbar-horizontal-brand-hover-color;
            background-color: $navbar-horizontal-brand-hover-bg;
        }
        &.active{
            color: $navbar-horizontal-brand-active-color;
        }
    }
    span.navbar-brand{
        color: $navbar-horizontal-brand-color;
        &:hover,
            &:focus {
            color: $navbar-horizontal-brand-hover-color;
        }
    }
    .navbar-text {
        color: $navbar-horizontal-color;
    }

    .navbar-nav {
        > li > a {
            padding: 15px 15px;
            font-size: 13px!important;
            color: $navbar-horizontal-link-color;

            &:hover,
                &:focus {
                color: $navbar-horizontal-link-hover-color;
                background-color: $navbar-horizontal-link-hover-bg;
            }
        }
        > .active > a {
            &,
            &:hover,
                &:focus {
                color: $navbar-horizontal-link-active-color;
                background-color: $navbar-horizontal-link-active-bg;
            }
        }
        > .disabled > a {
            &,
            &:hover,
                &:focus {
                color: $navbar-horizontal-link-disabled-color;
                background-color: $navbar-horizontal-link-disabled-bg;
            }
        }
    }

    .navbar-toggle {
        margin-bottom: 0;
        margin-right: 10px;
        margin-top: 0;
        padding: 16px 15px;
        float: none;
        border-color: $navbar-horizontal-toggle-border-color;
        &:hover,
            &:focus {
            background-color: $navbar-horizontal-toggle-hover-bg;

            .fa{
                color: $navbar-horizontal-link-hover-color;
            }
        }
        .fa{
            color: $navbar-horizontal-link-color;
        }
    }

    .navbar-collapse,
    .navbar-form {
        border-color: $navbar-horizontal-border;
    }
    .dropdown-header{
        color: #444;
        font-size: 14px;
        font-weight: bold;
        padding: 10px 20px 0px;
    }
    .dropdown-header-user{
        text-align: center;
        color: #444;
        font-size: 15px;
        padding: 15px 20px 3px;
        span{
            color: #777;
            display: block;
            font-size: 13px;
            line-height: 1.42857;
            padding: 3px 20px;
            white-space: nowrap;
            width: 100%;
            position: relative;
        }
    }
    .adminbar-avatar{
        text-align: center;
        img{
            margin: 0 auto;
        }
        .initials{
            top: 30px;
        }
    }
    #dropdown-sarch{
        margin: 0;
        padding: 0;
        min-width: 300px;
        .form-control{
            height: 49px;
            font-size: 16px;
        }
        .btn{
            font-size: 16px;
            padding: 12px 16px;
        }
    }
    // Dropdown menu items
    .nav .open > a, .nav .open > a:focus, .nav .open > a:hover{
        background-color: $navbar-horizontal-link-active-bg;
        color: $navbar-horizontal-link-active-color;

    }
    .navbar-nav {
        margin-top: 0;
        margin-bottom: 0;
        // Remove background color from open dropdown
        > .open > a {
            &:active,
                &:hover,
                &:focus {
                background-color: $navbar-horizontal-link-active-bg;
                color: $navbar-horizontal-link-active-color;
            }
        }

    }

    .navbar-collapse.collapse{
        display: block !important;
    }

    .navbar-nav>li, .navbar-nav{
        float: left !important;
    }

    .navbar-nav.navbar-right:last-child{
        //margin-right: -15px !important;
    }

    .navbar-right{
        float: right !important;
    }
    .navbar-header{
        float: left;
        width: 150px;
        position: relative;
        z-index: 1;
    }
    .navbar-collapse{
        float: right;
        width: 100%;
        position: absolute;
        z-index: 0;
        top: 0;
        left:0;
        right: 0;
        padding-left: 150px;

    }
    .navbar-collapse::after, .navbar-collapse::before, .navbar-header::after, .navbar-header::before, .navbar::after, .navbar::before {
        content: "";
        display: inline-block;
    }
    @media (min-width: $screen-sm) {
        .navbar-right{
            margin-right: 0px;
        }
    }
    @media (max-width: $screen-sm-max) {

        #dropdown-sarch{
            min-width: 210px;
        }
        .navbar-nav{
            .open{
                .dropdown-menu {
                    position: absolute;
                    overflow-y: auto;
                    max-height: 80vh;
                    background-color: #fff;
                    @include box-shadow(0 6px 12px rgba(0,0,0,.175));
                }
            }
            &.navbar-right{

                .open{
                    .dropdown-menu {
                        left: auto;
                        right: 0;
                    }
                }
            }
        }

    }

}
.navbar-frontend{
    min-height: 55px;
    .navbar-header {
        width: 75px;
    }
    .navbar-collapse {
        padding-left: 95px;
    }

    @media (min-width: $screen-sm) {
        .navbar-collapse {
            padding-left: 75px;
        }
    }
}
.adminbar{
    padding-top: 55px;
    #navbar-main{
        top: 55px!important;
    }
}