/* AVATARS */
.avatar      { @include avatar(95px); }
.avatar-xs   { @include avatar(35px); }
.avatar-sm   { @include avatar(45px); }
.avatar-lg   { @include avatar(250px); }
.avatar-border{ 
    img{
        border: 5px solid #fff;
    }
}
.avatar-circle{
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    img{        
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
    }

}
@for $i from 1 through 10{
    .avatar-color-#{$i}{
        background-color: map-get($user_colors, c#{$i});
    }
}
/* END AVATARS */