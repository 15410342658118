#user_content{
    background-color: darken(#f5f5f5, 2%);
    padding: 60px 0;
    -webkit-box-shadow: inset 0px 11px 8px -10px rgba(0, 0, 0, 0.35), inset 0px -11px 8px -10px rgba(0, 0, 0, 0.35);
    box-shadow: inset 0px 11px 8px -10px rgba(0, 0, 0, 0.35), inset 0px -11px 8px -10px rgba(0, 0, 0, 0.35);

    .dropdown-menu{
        margin: 0;
        padding: 0;
        box-shadow: none;
        a,
        .btn{
            display: block;
            width: 100%;
            padding: 6px 15px;
            font-size: 12px;
            text-align: left;
            text-transform: uppercase;
            .fa{
                margin-right: 5px;

            }
        }
    }
    #bulk_form{
        .input-group{
            .input-sm{
                height: 32px;
            }
        }
        .btn{
            border-width: 0;
        }
        .dropdown-menu{
            left: auto;
            right: 0;
            min-width: 100px;
        }


    }
    @media (min-width: $screen-sm){
        &{
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}

@media (min-width: 1600px) {
    .col-xlg-2{
        width: 16.66667%;
    }
}