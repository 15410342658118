/* image */
figure.image {
    display: inline-block;
    margin-bottom: 20px;
}

figure.align-left,
figure.text-left {
    float: left;
}

figure.align-right,
figure.text-right {
    float: right;
}

figure.image img {
}

figure.image figcaption {
    margin: 6px 0;
    font-size: 80%; 
}

.fill {
    width: 100%;
    height: 100%;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}
.cover{
    background-attachment: fixed;
    background-position: center, center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}
.img-cover {
    display: block;
    min-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;

}
div[class*='max-h-'] {
    .img-cover{
        width: auto;
        position: absolute;
        z-index: 1;
    }
}
.img-border{
    border: 2px solid $main-color;
}
.img-hover-hiden{
    position: absolute;
    top: 0; 
    left: 0;
    display: none;
}
/* end image */
.image-flip{
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    min-height: 270px;
    .image-flip-wrapper{
        position: absolute;
        width: 140px;
        height: 140px;
        border-radius: 50%;
        perspective: 800px;
        transition: all 0.4s ease-in-out;
        margin: auto;
        top: 0; left: 0; bottom: 0; right: 0;
        background: #f9f9f9 ;        
        .image-flip-content{
            position: absolute;
            width: 140px;
            height: 140px;
            border-radius: 50%;
            transition: all 0.4s ease-in-out;
            transform-style: preserve-3d;
            > div {
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-position: center center;
                backface-visibility: hidden;
                background-size: 140px 140px;
            }
            .image-flip-front{
                z-index: 1;
            }
            .image-flip-back{
                transform: rotate3d(0,1,0,180deg);
                z-index: 0;
            }
        }
    }
    &:hover{
        .image-flip-wrapper{
            .image-flip-content{
                transform: rotate3d(0,1,0,-180deg);
                .image-flip-front{
                    z-index: 1;
                }
                .image-flip-back{
                    z-index: 2;
                }

            }
        }
    }
    @media (min-width: 400px) {
        &{
            min-height: 270px;
            .image-flip-wrapper{
                width: 180px;
                height: 180px;
                .image-flip-content{
                    position: absolute;
                    width: 180px;
                    height: 180px;
                    > div{                        
                        background-size: 180px 180px;
                    }
                }
            }
        }
    }
}
