.blog_archive{
    ul{  
        margin: 0;
        padding: 0;
        list-style:none;
        li{
            a{
                position: relative;
                display: block;
                padding: 5px 15px 5px 0;
                color: $blog-archive-color;
                font-size: $blog-archive-font-size;
                font-weight: $blog-archive-font-weight;
                .badge{
                    position: absolute;
                    right: 0;
                    z-index: 1;
                }
                &:hover, &:focus{
                    color: $blog-archive-color-hover;
                }
            }
            > ul{
                display: none;
                > li{
                    > a{
                        padding-left: 10px;
                        font-size: $blog-archive-font-size - 1;
                    }
                    > ul{
                        display: none;
                        > li{
                            > a{
                                padding-left: 20px;
                                font-size: $blog-archive-font-size -2;
                                &:before{
                                    content: '-';
                                    position: absolute;
                                    left: 10px;
                                    z-index: 1;
                                }
                            }
                        }
                    }
                }
            }
            &.active{
                > a{
                    font-weight: $blog-archive-font-weight-active;
                }
                > ul{
                    display: block;
                    > li{
                        &.active{
                            > a{
                                font-weight: $blog-archive-font-weight-active;
                            }
                            > ul{
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}