/* FORMS */
.tlw{position:relative; clear:both;}
.input_box{position:relative;}
.input_required{position:absolute; right:5px; bottom:20px; font-size:6pt; color:#E74C3C;}
.input_textarea_required{position:absolute; right:5px; top:27px; font-size:6pt; color:#E74C3C;}
.input_select_required{position:absolute; right:25px; bottom:12px; font-size:6pt; color:#E74C3C;}

/**** askAboutProductForm ****/
#askAboutProductForm label{ font-weight: normal; color:#666;}
/**** end askAboutProductForm ****/

.form-control{ border-radius: 0px; box-shadow: none; color: $text-color;  font-weight: 300;}
/**** contact ****/
.wicode-form{
    label{ 
        font-size:13px;
        color:$text-color;  
        font-weight: 400; 
        text-transform: uppercase;
    }
    .has-feedback{
        .form-control-feedback { 
            right: 5px;
            color:#8e8e8e;
        }
        label ~ .form-control-feedback { 
            top: 35px;
        }
    }
    .form-group{
        display: block;
        width: 100%;
    }
    textarea.form-control{
        min-height: 295px;
    }
    .form-control{
        border-color: lighten($main-color, 40%);

    }
    #submit_contact{
        margin-top: 25px;
        float: right;
        text-transform: uppercase;
    }
    &.row{

        @media (min-width: 768px) {
            .col-xs-12:first-of-type{
                .form-group{
                    margin-bottom: 18px;
                }
            }
            .tlw{
                margin-top: 1px;
            }
        }
    }
    &#login_form{
        .login-area{
            margin-top: 20px;
            .form-group{
                margin-bottom: 15px;
                .form-control{
                    border-width: 0;
                    height: 37px;
                }
            }
            .btn{
                margin-top: 5px;
                min-width: 176px;
            }

        }

        .btn{
            font-size: 18px;
            text-transform: uppercase;
        }
    }
    &#register_form{
        .h2{
            font-weight: 800;
            margin-bottom: 50px;
        }
        .form-group{
            .form-control{
                height: 37px;
            }
        }
        .btn{
            padding: 4px 12px;
            font-size: 18px;
            min-width: 176px;
            text-transform: uppercase;
            &#submit_register{
                margin-top: 28px;
            }
        }
    }
    &#download_form{
        width: 270px;
        position: absolute;
        bottom: 0;
        right: 40px;
        z-index: 1;
        label{
            color: $white;
            .fa{
                color: inherit;
            }
        }
        .form-group{
            font-size: 16px;
            font-weight: 700;
        }
        .btn{
            white-space: normal;
            text-transform: uppercase;
            font-size: 18px;

            margin-top: 40px;
            margin-bottom: 40px;
            padding: 6px 33px;
        }

        @media (max-width: 565px) {
            width: calc(100% - 30px);
            left: 15px;
            right: 15px;
        }

        @media (min-width: $screen-md)  and (max-width: $screen-md-max) {
            &{
                width: 230px;
                right: 20px;
                .form-group{
                    font-size: 14px;

                }
                .btn{
                    margin-top: 20px;
                    margin-bottom: 20px;
                    padding: 6px 38px;
                    font-size: 14px;
                }
            }
        }
    }
    &#newsletter_form{
        .form-control{
            font-size: 13px;
            font-weight: 300;
            border-color: #fff;
            height: 40px;
            color: $text-color;
            text-transform: uppercase;
        }
        .btn{
            padding: 6px 12px;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 300;
        }

        @media (min-width: $screen-sm){
            .form-control{
                height: 46px;
            }
            .btn{
                padding: 10px 12px;
                font-size: 15px;
            }
        }
    }
}


/**** end contact ****/
/* END FORMS */
