.social{
    display: block;
    position: relative;
    ul{
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
        li{
            display: inline-block;
            margin-bottom:15px;
            margin-right:15px;
            color:#fff;
            text-align: center;
            float: left;
            border-radius: 4px;
            &:last-child{
                margin-right: 0px;
            }
            a{
                width: inherit;
                height:inherit;
                display: block;
                text-align: center;
                line-height: inherit;
                color: lighten($text-color, 20%);
                line-height: 1;
                @include transition(all .2s ease-in-out);
                &:hover, &:focus{
                    color:$text-color;
                }
                .fa{
                    font-size: 14px;
                    vertical-align: -25%;
                }

            }
        }
        &.social-circle{
            li{
                a{
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    border: 1px solid lighten($text-color, 30%);
                    display: block;
                    text-align: center;
                    line-height: inherit;
                    color: lighten($text-color, 30%);
                    opacity: 0.9;
                    @include transition(all .2s ease-in-out);
                    &:hover, &:focus{
                        color:$text-color;
                        border-color:$text-color;
                        opacity: 1;
                    }
                    .fa{
                        font-size: 20px;
                        vertical-align: -70%;
                        position: relative;
                        left: 1px;

                    }

                }
            }
        }
        &.social-inline{        
            li{
                display: inline-block;
            }
        }
        &.social-share{
            li{
                width: auto;
                a{
                    &:hover, &:focus{
                    }
                    .fa{
                        font-size: 18px;
                    }
                }
            }
        }
        &.social-square{
            li{
                opacity: 0.8;
                @include transition(ease .3s);
                a{
                    width: 45px;
                    height: 45px;
                    line-height: 40px;
                    padding: 1%;
                    border: 1px solid rgba(75,78,83, 0.4);
                    border-radius: 0px;
                    cursor: pointer;
                    &:hover, &:focus{                 
                        color: rgba(75,78,83, 1);
                        border: 1px solid rgba(75,78,83, 1);
                    }
                    .fa{
                        font-size: 22px;
                    }
                }
            }
            &:hover > li {
                opacity: 0.5;
            }

            &:hover > li:hover {
                opacity: 1;
            }
        }
        &.social-color{        
            li{
                a{
                    color: $white;
                    opacity: 1;
                    background-color: #fafafa;
                    border-color: #fafafa;
                    &.facebook{
                        background-color: #3C569A;
                        border-color: #3C569A;
                        &:hover, &:focus{
                            background-color: darken(#3C569A, 10%);
                            border-color: darken(#3C569A, 10%);
                            color:#fff;
                        }
                    }
                    &.skype{
                        border-color: #2F9BFF;
                        background-color: #2F9BFF;
                        &:hover, &:focus{
                            border-color: darken(#2F9BFF, 10%);
                            background-color: darken(#2F9BFF, 10%);
                            color:#fff;
                        }
                    }
                    &.twitter{
                        border-color: #00AAEF;
                        background-color: #00AAEF;
                        &:hover, &:focus{
                            border-color: darken(#00AAEF, 10%);
                            background-color: darken(#00AAEF, 10%);
                            color:#fff;
                        }
                    }
                    &.google,
                    &.googleplus{
                        border-color: #E31C2E;
                        background-color: #E31C2E;
                        &:hover, &:focus{
                            border-color: darken(#E31C2E, 10%);
                            background-color: darken(#E31C2E, 10%);
                            color:#fff;
                        }
                    }
                }
            }
        }
    }
}
#blog-wrapper,
#project-wrapper,
#realization-wrapper{
    .social_share{
        margin-top: -15px;
        li{
            a{
                width: 35px;
                height: 35px;
                line-height: 28px;
                .fa{
                    font-size: 20px;
                }
            }
        }

    }
}
footer{    
    .social{
        ul{
            text-align: left;
            li{
                float: none;
                margin-bottom: 10px;
                margin-right: 10px;
                a{          
                    color: #fff!important;         
                    line-height: 1!important;
                    height: auto!important;
                    width: auto!important;
                    border-radius: 50%!important;
                    border-width: 0!important;
                    .fa{
                        font-size: 18px!important; 
                    }
                }
            }
        }
    }

    @media (max-width: $screen-xs-max){
        .social{
            ul{
                text-align: center;
            }
        }
    }
}