.login-table {
    width: 100%;
    height: 100%;
    display: table;
    .login-cell {
        display: table-cell;
        vertical-align: middle;
        .login-cell-content {
            margin: 0 auto;
            width: 320px;
            background-color: #f9f9f9;
            padding: 0 15px;
            -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
            -moz-box-shadow:    0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
            box-shadow:         0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
            .login-header {
                margin: 0 -15px;
                padding: 25px 15px;
                background-color: #fff;
                h1 {
                    margin: 0;
                    font-weight: 500;
                    font-size: 18px;
                    text-transform: uppercase;
                }
                p {
                    margin: 0;
                    font-weight: 300;
                    font-size: 14px;
                }
            }
            .login-content {
                padding: 20px 0 5px;
                font-size: 12px;
                .form-group {
                    font-size: 11px;
                    &.last a {
                        font-size: 11px;
                    }
                }
            }
            .login-footer {
                margin: 0 -15px;
                padding: 15px 15px;
                background-color: #fafafa;
                font-size: 11px;
                font-weight: 500;
                text-transform: uppercase;
                color: #888;
                a {
                    color: #666;
                    &:hover, &:focus {
                        color: #333;
                        outline: none;
                        text-decoration: none;
                    }
                }
            } 
            @media (min-width: 768px) {
                width: 460px;
            }
        }
    }
}




