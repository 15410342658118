$lato-font-path: '../fonts/lato' !default;
@font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/lato-bold.eot');
    src: url('#{$lato-font-path}/lato-bold.eot?#iefix') format('embedded-opentype'),
        url('#{$lato-font-path}/lato-bold.woff2') format('woff2'),
        url('#{$lato-font-path}/lato-bold.woff') format('woff'),
        url('#{$lato-font-path}/lato-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/lato-bolditalic.eot');
    src: url('#{$lato-font-path}/lato-bolditalic.eot?#iefix') format('embedded-opentype'),
        url('#{$lato-font-path}/lato-bolditalic.woff2') format('woff2'),
        url('#{$lato-font-path}/lato-bolditalic.woff') format('woff'),
        url('#{$lato-font-path}/lato-bolditalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/lato-mediumitalic.eot');
    src: url('#{$lato-font-path}/lato-mediumitalic.eot?#iefix') format('embedded-opentype'),
        url('#{$lato-font-path}/lato-mediumitalic.woff2') format('woff2'),
        url('#{$lato-font-path}/lato-mediumitalic.woff') format('woff'),
        url('#{$lato-font-path}/lato-mediumitalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/lato-medium.eot');
    src: url('#{$lato-font-path}/lato-medium.eot?#iefix') format('embedded-opentype'),
        url('#{$lato-font-path}/lato-medium.woff2') format('woff2'),
        url('#{$lato-font-path}/lato-medium.woff') format('woff'),
        url('#{$lato-font-path}/lato-medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/lato-regular.eot');
    src: url('#{$lato-font-path}/lato-regular.eot?#iefix') format('embedded-opentype'),
        url('#{$lato-font-path}/lato-regular.woff2') format('woff2'),
        url('#{$lato-font-path}/lato-regular.woff') format('woff'),
        url('#{$lato-font-path}/lato-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/lato-italic.eot');
    src: url('#{$lato-font-path}/lato-italic.eot?#iefix') format('embedded-opentype'),
        url('#{$lato-font-path}/lato-italic.woff2') format('woff2'),
        url('#{$lato-font-path}/lato-italic.woff') format('woff'),
        url('#{$lato-font-path}/lato-italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/lato-light.eot');
    src: url('#{$lato-font-path}/lato-light.eot?#iefix') format('embedded-opentype'),
        url('#{$lato-font-path}/lato-light.woff2') format('woff2'),
        url('#{$lato-font-path}/lato-light.woff') format('woff'),
        url('#{$lato-font-path}/lato-light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('#{$lato-font-path}/lato-lightitalic.eot');
    src: url('#{$lato-font-path}/lato-lightitalic.eot?#iefix') format('embedded-opentype'),
        url('#{$lato-font-path}/lato-lightitalic.woff2') format('woff2'),
        url('#{$lato-font-path}/lato-lightitalic.woff') format('woff'),
        url('#{$lato-font-path}/lato-lightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

/* END Ubuntu Bold Italic */