footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    h1,h2,h3,h4,h5,
    .h1,.h2,.h3,.h4,.h5{
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 25px;
        position: relative;
    }
    p{
        font-size: 16px;
        letter-spacing: 0.1px;
    }
    .copy{
        font-size: 12px; 
        font-weight: 400;
        letter-spacing: 0px;
        text-transform: uppercase;
        color: darken($white, 25%);
    }
    .created{ 
        font-size:12px; 
        font-weight: 200;
        letter-spacing: 0px;
        a{
            letter-spacing: 0px;
            font-weight: 200;
        }
    }
    
    @media (max-width: $screen-xs-max){
        text-align: center;
    }
} 