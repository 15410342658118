#loader{ 
    z-index: 2000; 
    position: fixed; 
    height: 100%; 
    width: 100%; 
    background-color: rgba(255,255,255, .75); 
    margin: 0 auto; 
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0;
    .spinner-logo{
        width:320px; 
        height:200px; 
        position:relative; 
        margin: 10px auto;
        background-image: url('../img/logo_m.png');
        background-position: center center;
        background-repeat: no-repeat;
    }
}
.spinner {
    width: 70px;
    height: 20px; 
    position: fixed;
    top: 45%; 
    left: 50%; 
    right: 0;
    bottom: 0; 
}
.spinner > div {
    width: 18px;
    height: 18px;
    background-color: $main-color;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% { 
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% { 
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}