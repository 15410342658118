.containers{
    &.container-fluid{
        padding: 0;
        overflow-x: hidden;
    }
    &.container{        
        .container-fluid{
          //  padding-left: floor(($grid-gutter-width / 2));
          //  padding-right: floor(($grid-gutter-width / 2));
        }
    }
    .col{
        > .item{
            position: relative;            
        }
    }
}