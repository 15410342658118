/* navbar-footer */
.navbar-footer{
    display: block;
    position: relative;
    text-align: right;
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    ul{
        margin: 0;
        padding: 0;
        list-style:none;
        li {
            display: inline-block;
            position: relative;
            &:after {
                content: '|';
                position: absolute;
                color: darken($white, 25%);
                top: -2px;
                z-index: 1;
            }
            > a {
                padding: 2px 17px;
                position: relative;
                display: block;
                font-size: 12px;
                color: darken($white, 25%);
                font-weight:400;
                text-transform: uppercase;
                &:hover, &:focus{
                    color: darken($white, 5%);
                }
            }
            &:first-child > a{
                padding-left: 0px;
            }
            &:last-child{
                &:after {
                    content: '';
                }
                > a{
                    padding-right: 0px;
                }
            }
        }
    }
    @media (max-width: $screen-xs-max){  
        display: none;
    }
}