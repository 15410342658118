/* Breadcrumb */
.breadcrumbs{
    width:100%;
    position:relative;
    padding: 0;
    text-align: left;
    .breadcrumb {
        background-color: transparent;
        margin-bottom: 0px;
        padding: 0;
        border-radius:0px;
        line-height: 1.2;
        padding-bottom: 4px;
        li{
            a{
                color:rgba(0,0,0,0.7);
                font-size:11px;
                text-transform: uppercase;
                font-weight: 200;
                &:hover, &:focus{
                    color: rgba(0,0,0,1);
                }
                .fa{
                    font-size: 13px;
                    color:
                    rgba(0,0,0,.35);
                }
            }  
            &:last-child{
                a{
                    font-weight: 400;
                    color: rgba(0,0,0,1);
                }
            }
            &:only-child{
                a{
                    color:rgba(0,0,0,0.7);
                }
            }
            & + li{
                &:before{
                    content: ' / ';
                    top: 1px;
                    position: relative;
                    color:rgba(0,0,0,0.35);
                }

            } 
            @media (max-width: 420px){
                &:first-child{
                    + li{
                        &:before{
                            content: '';
                        }
                    } 
                    a{
                        display: none;
                    }
                }

            }
        }
    }
}
.bg-main{
    .breadcrumbs{
        .breadcrumb {
            li{
                a{
                    color:rgba(255,255,255,0.7);
                    &:hover, &:focus{
                        color: rgba(255,255,255,1);
                    }
                }  
                &:last-child{
                    a{
                        color: rgba(255,255,255,1);
                    }
                }
                &:only-child{
                    a{
                        color:rgba(255,255,255,0.7);
                    }
                }
                & + li{
                    &:before{
                        color:rgba(255,255,255,0.7);
                    }

                } 
            }
        }
    }
}
/* END Breadcrumbs */
