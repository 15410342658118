.form-search{
    margin: 15px 0;
    .form-control{
        height: 50px;
    }
    .btn{
        height: 50px;
    }
    &.small{
        .form-control{
            height: 35px;
        }
        .btn{
            height: 35px;
        }
    }

}
.search-results{
    padding-bottom: 50px;
    .search-content {
        background-color: #FFFFFF;
        color: inherit;
    }

    .search-for {
        font-size: 16px;
        font-weight: 200;
        span{ 
            font-weight: 400;
        }
    }
    .search-result{
        h3 {
            margin-bottom: 0;
            color: #1E0FBE;
        }

        .search-link {
            display: block;
            color: $main-color;
        }

        p {
            margin-top: 5px;
        }
    }

    .hr-line-dashed {
        border-top: 1px dashed #E7EAEC;
        color: #ffffff;
        background-color: #ffffff;
        height: 1px;
        margin: 20px 0;
    }

}

.search-form {
    margin-top: 10px;
}
