.header{
    h1,h2,h3,h4,h5,h6,
    .h1,.h2,.h3,.h4,.h5,.h6{
        margin-top: 0;
        margin-bottom:0;
        small{
            display: block;
            margin-top: 5px;
            margin-left: 0px;
            font-size: 70%;
            color: initial;
            opacity: 0.75;
            letter-spacing: 3px;
            font-weight: 200;
        }    
        strong{
            display: block;
            font-weight: 700;
        }                  
    }
}