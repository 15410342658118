.blog-popular{
    .blog-item{
        position: relative;
        background-color: $blog-popular-bg;
        padding-bottom: 10px;
        margin-bottom: 10px;
        .blog-link{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 2;
        }
        .blog-image{
            position: relative;
            height: 200px;
            display: block;
            overflow: hidden;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                @include translate(-50%, -50%);
                height:200px;
                min-width:100%; 
                width: auto;
                vertical-align: middle;
                object-fit: cover;
                object-position: center;
            }
            .no-image{
                height: 200px!important;
            }
        }
        .blog-header{
            padding: 20px 0 0;
            .blog-title{
                font-size: $blog-popular-font-size-title;
                font-weight: $blog-popular-font-weight-title;             
                margin: 0!important;
                a{
                    color: $blog-popular-color-title;
                }
            }
        }
        .blog-info{
            padding: 0 0 5px;
            .blog-date{
                font-size: $blog-popular-font-size-date;
                font-weight: $blog-popular-font-weight-date;   
                color: $blog-popular-color-date; 
            }
            .blog-author{
                font-size: $blog-popular-font-size-author;
                font-weight: $blog-popular-font-weight-author;   
                color: $blog-popular-color-author; 
            }
            .blog-comments{
                font-size: $blog-popular-font-size-comments;
                font-weight: $blog-popular-font-weight-comments;   
                color: $blog-popular-color-comments; 
            }
        }
        .blog-content{
            padding: 15px 0;
            font-size: $blog-popular-font-size-content;
            font-weight: $blog-popular-font-weight-content;   
        }
        .blog-footer{
            padding: 0;
            text-align: right;
            a{
                font-size: $blog-popular-font-size-btn;
            }
        }
        &:hover, &:focus{
            .blog-header{
                .blog-title{
                    a{
                        color: $blog-popular-color-title-hover;
                    }
                }
            }
            .blog-footer{
                a{
                    color: $blog-popular-color-title-hover;
                }
            }
        }
    }   
}
