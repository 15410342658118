
/* Qa */
.qa {
    border-radius: 0;
    border-width: 0 !important;
    margin-bottom: 0px;
    .panel-qa{
        border-radius: 0;
        border-width: 0 !important;
        box-shadow: none;
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .panel-heading{        
        border-radius: 0;
        border-width: 0 !important;
        padding:0;
        img{
            padding:10px;
        }
    }
    .panel-title {
        height:100%;
        width:100%;
        border-radius: 0;
        border-width: 0 !important;
        position: relative;
        &:after{
            content: '';
            top:0;
            bottom:0;
            right: 45px;
            width: 3px;
            background-color: $white;
            position: absolute;
            z-index: 1;

        }
        a{            
            width:100%;
            display: block;
            padding:12px 45px 12px 15px;
            margin-bottom:0px;
            font-size: 15px;
            font-weight: 400;
            text-transform: uppercase;
            position: relative;
            .fa-indicator{
                position: absolute;
                content: '';
                top: 50%;
                right: 16px;
                transform: translateY(-50%);
                z-index: 1;
                &:before{
                    content: '\f068';
                }
            }
            &.collapsed{
                .fa-indicator{
                    &:before{
                        content: '\f067';
                    }
                }
            }
        }

    }
    .panel-collapse{
        border-radius: 0px;
        border-width: 0 !important;
        .panel-body{
            border-radius: 0px;
            border-width: 0 !important;
            padding-left: 0;
            padding-right: 0;
            .containers{
                padding: 0px;
                .col-xs-12{

                    &:nth-child(3n){
                        @media (max-width: $screen-sm-max) {
                            &{

                                clear:both;
                            }
                        }
                    }
                }
            }
        }
    }
    .panel-excerpt{

        .panel-body{
            border-radius: 0px;
            border-width: 0 !important;
            padding-left: 0;
            padding-right: 0;
        }
    }
    &.qa-white{
        .panel-qa{
            border-color: #dcdcdc;
            border-width: 1px !important;
            border-radius: 0px;
            > .panel-heading {
                background-color: #ffffff; 
                border-color: #ffffff; 
                color: $main-color;
                &:hover, &.active { 
                    background-color: darken(#ffffff, 5%); 
                    border-color: darken(#ffffff, 5%); 
                    color: lighten($main-color, 10%);
                }
                .panel-title{            
                    a{
                        color: $main-color;
                        &:hover, &:focus, &:visited{
                            color: $main-color;
                        }
                        .fa{
                            color: #404040;
                        }

                    }
                }
            }
        }
        .panel-body{
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    &.qa-black{
        .panel-qa{
            > .panel-heading {
                background-color: #141414; 
                border-color: #141414; 
                border-radius: 0px;
                color: #fff;
                &:hover { 
                    background-color: lighten($gray-base, 7%); 
                    border-color: lighten($gray-base, 7%); 
                    color: #fff;
                }
                .panel-title{
                    a{
                        color: #fff;
                        &:hover, &:focus, &:visited{
                            color: darken(#fff, 15%); 
                        }
                        .fa{
                            color: darken(#fff, 15%); 
                        }

                    }
                }
            }
        }
    }
    &.qa-gray{
        .panel-qa{
            > .panel-heading {
                background-color: #f7f8fa; 
                border-color: #f7f8fa; 
                border-radius: 0px;
                color: #404040;
                &:hover { 
                    background-color: darken(#f7f8fa, 10%); 
                    border-color: darken(#f7f8fa, 10%); 
                    color: #404040;
                }
                .panel-title{
                    a{
                        color: $gray-base;
                        &:hover, &:focus, &:visited{
                            color: $gray-base;
                        }
                        .fa{
                            color: #404040;
                        }

                    }
                }
            }
        }
    }
    &.qa-project-manager{
        .panel-qa{
            -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
            -moz-box-shadow:    0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
            box-shadow:         0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
            border-radius: 0px;
            > .panel-heading {
                background-color: #ffffff; 
                border-color: #ffffff; 
                color: $main-color;
                &:hover { 
                    background-color: darken(#ffffff, 5%); 
                    border-color: darken(#ffffff, 5%); 
                    color: lighten( $main-color, 10%);
                }

                .panel-title {
                    &:after{
                        display: none;

                    }           
                    a{
                        padding:15px 125px 15px 15px;
                        font-size: 13px;
                        color: $main-color;
                        &:hover, &:focus, &:visited{
                            color: $main-color;
                        }
                        .fa{
                            color: $gray-base;
                        }


                    }
                }
            }
        }        
        .panel-collapse{
            background-color: #f9f9f9;
            border-top: 1px solid #f5f5f5;
            .panel-body{
                padding: 0 15px;
                .nav-tabs{
                    margin: 10px -15px!important;
                    padding: 0 15px!important;
                }
                .tab-content{
                    > .tab-pane{
                        box-shadow: none!important;
                    }
                }
            }
        }
    }
}
/* END Qa */