.info-bar{
    position: relative;
    width: 100%;
    min-height: 200px;
    &:before{
        content:'';
        position: absolute;
        top:0;
        left:0;
        right: 0;
        bottom: 0;
        opacity: 0;
        @include transition(all .3s ease-in-out);
    }  
    &:hover, &:focus{
        &:before{
            z-index: 1;
            opacity: 1;
            background-color: rgba(0,0,0,.25);
            @include transition(all .3s ease-in);
        }
    }
    .info-bar-link{
        position: absolute;
        top:0;
        left:0;
        right: 0;
        bottom: 0;
        z-index: 2;
    }
    .info-bar-img{
        width: 100%;
        max-width: 576px;
        height: 200px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .info-bar-overlay{
        background-color: rgba(0,0,0,.15);
    }
    .info-bar-content{
        height: 145px;
        width:100%;
        display: table;
        .info-bar-text{
            display: table-cell;
            vertical-align: middle;
            font-family: $font-family-serif;
            text-transform: uppercase;
            line-height: 1.2;
            font-size: 18px;
            color: #fff;
            height: 100%;
            width: 100%;
            padding: 15px
        }
    }
    .info-bar-left{
        position: relative;
        height: 200px;
        .info-bar-img{
            background-position: center right;
        }
    }
    .info-bar-right{
        height: 200px;
        position: relative;
        display: none;
        .info-bar-img{
            background-position: center left;
        }
    }

    @media (max-width: 420px){ 
        .container{
            padding: 0;
        }
        .info-bar-left{
            position: relative;
            height: 145px;
            .info-bar-img{
                height: 145px;
                background-position: center right;
            }
        }
    }
    @media (max-width: $screen-xs-max){ 
        .container{
            padding: 0;
        }
    }
    @media (min-width: $screen-xs) and (max-width: $screen-sm-max){ 
        &{
            padding-bottom: 200px;
        }
        .info-bar-overlay{
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            right: 0;
        }
    }
    @media (min-width: 500px){   

        .info-bar-content{
            height: 200px;
            .info-bar-text{
                font-size: 20px;
            }
        }
    }
    @media (min-width: $screen-sm){   

        .info-bar-content{
            height: 200px;
            .info-bar-text{
                font-size: 24px;
            }
        }
        .info-bar-img{
            width: 576px;
            position: absolute;
            top:0;
            bottom:0;
            right: 0;
        }   
        .info-bar-right{
            display: block;
            .info-bar-img{
                right: auto;
                left:0;
            }
        }
    }
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max){        
        .info-bar-left{
            .info-bar-img{
                right: -20%;
            }
        }
        .info-bar-right{
            .info-bar-img{
                left: 20%;
            }
        }
    }
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) and (orientation: portrait){   
        .info-bar-left{
            .info-bar-img{
                right: -40%;
            }
        }
        .info-bar-right{
            .info-bar-img{
                left: 40%;
            }
        }
    }
    &.info-bar-small{
        min-height: 83px;
        .info-bar-logo{
            .info-bar-logo-img{
                width: 100%;
                height: 83px;
                background-repeat: no-repeat;
                background-size: auto 60px;
                background-position: left center;
            }
        }
    }
}