.realizations-grid{
    .realizations-item{
        position: relative;
        background-color: $realizations-grid-bg;
        margin-bottom: 30px;
        @include transition(all 0.35s ease-out);
        .realizations-link{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 10;
        }
        .realizations-image{
            position: relative;
            height: 180px;
            display: block;
            overflow: hidden;
            z-index: 2;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                @include translate(-50%, -50%);
                height:180px;
                min-width:100%; 
                width: auto;
                vertical-align: middle;
                object-fit: cover;
                object-position: center;
                @include transition(all 0.35s ease-out);
            }
            .no-image{
                height: 200px!important;
            }
        }
        .realizations-header{
            position: absolute;
            width: 100%;
            text-align: left;
            padding: 20px;
            bottom: 0;
            text-align: left;
            z-index: 3;
            background-color: rgba(0,0,0,.25);
            .realizations-title{
                font-size: $realizations-grid-font-size-title;
                font-weight: 500;                
                margin: 0;
                text-align: center;
                z-index: 4;
                a{
                    color: $realizations-grid-color-title;
                    small{
                        margin-top: 5px;
                        display: block;
                        color: $realizations-grid-color-title;
                    }
                }
            }
        }
        .realizations-info{
        }
        .realizations-content{
        }
        .realizations-footer{
            padding: 0 15px 20px;
            text-align: right;
            a{
                font-size: $realizations-grid-font-size-btn;
            }
        }
        &:hover, &:focus{
            .realizations-footer{
                a{
                    color: $realizations-grid-color-title-hover;
                }
            }
        }
        @media (min-width: $screen-sm){
            .realizations-header{
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 0;
                background-color: rgba(0,0,0,.1);
                @include transition(all 0.35s ease-out);
                opacity: 0;
                .realizations-title{
                    font-size: $realizations-grid-font-size-title + 6;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    @include translate(-50%, -50%);
                    width: 100%;
                    a{

                        text-shadow: 1px 2px 2px rgba(0,0,0,.45);
                    }
                }
            }

            &:hover, &:focus{
                .realizations-header{
                    z-index: 4;
                    opacity: 1;
                }

            }
        }
    }
    .one{
        .realizations-item{
            .realizations-image{
                height: 260px;
                img {
                    height:260px;
                }
                .no-image{
                    height: 260px!important;
                }
            }
        }
    }
    .two{
        .realizations-item{
            .realizations-image{
                height: 320px;
                img {
                    height:320px;
                }
                .no-image{
                    height: 320px!important;
                }
            }
        }
    }
}
