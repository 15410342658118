.blog-list{
    .blog-item{
        position: relative;
        background-color: $blog-list-bg;
        padding: 0 15px; 
        margin-bottom: 30px;
        .blog-link{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 2;
        }
        .blog-image{
            position: relative;
            height: 220px;
            display: block;
            overflow: hidden;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                @include translate(-50%, -50%);
                height:220px;
                min-width:100%; 
                width: auto;
                vertical-align: middle;
                object-fit: cover;
                object-position: center;
            }
            .no-image{
                height: 220px!important;
            }
        }
        .blog-header{
            padding: 0 15px;
            .blog-title{
                font-size: $blog-list-font-size-title;
                font-weight: $blog-list-font-weight-title;             
                margin: 0;
                a{
                    color: $blog-list-color-title;
                }
            }
        }
        .blog-info{
            padding: 0 15px 15px;
            .blog-date{
                font-size: $blog-list-font-size-date;
                font-weight: $blog-list-font-weight-date;   
                color: $blog-list-color-date; 
            }
            .blog-author{
                font-size: $blog-list-font-size-author;
                font-weight: $blog-list-font-weight-author;   
                color: $blog-list-color-author; 
                margin-left: 5px;
            }
            .blog-comments{
                font-size: $blog-list-font-size-comments;
                font-weight: $blog-list-font-weight-comments;   
                color: $blog-list-color-comments; 
            }
        }
        .blog-content{
            padding: 0 15px 5px;
            color: $blog-list-color-content;
            @include transition(all 0.35s ease-out);
        }
        .blog-footer{
            padding: 15px;
            text-align: right;
            a{
                font-size: $blog-list-font-size-btn;
            }
        }
        &:hover, &:focus{
            .blog-header{
                .blog-title{
                    a{
                        color: $blog-list-color-title-hover;
                    }
                }
            }
            .blog-content{
                color: $blog-list-color-content-hover;
            }
            .blog-footer{
                a{
                    color: $blog-list-color-title-hover;
                }
            }
        }
    }
}
