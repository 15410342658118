a, .btn, .dropdown-toggle
{ 
    opacity:1; 
    &:hover{        
        @include transition(all .6s ease);
    }
}
a,a:hover,a:focus,.btn,.btn:hover,.btn:focus{
    text-decoration: none; 
    opacity:1;
    outline: none;
    border-radius: 0px; 
}
.btn:focus, .btn.focus, .btn:active:focus, .btn.focus:active, .btn.active:focus, .btn.active.focus{
    outline: none;
}
a:not(.btn), .btn-link{
    color: #274c82;
    &:hover, &:focus{
        color: lighten($text-color, 25%); 
    }
}
.text{
    a:not(.btn), .btn-link{
        color: #274c82;  
        position: relative;
        &:after{
            content: '';
            position: absolute;
            bottom: -2px;
            width: 0px;
            height: 1px;
            background-color: transparent;
            z-index: 1;
            opacity:1; 
            left: 0;
            right: 0;
            @include transition(all ease 0.5s);
        } 
        &:hover, &:focus{
            &:after{
                width: 100%;
                background-color: lighten($main-color, 20%);
            }

        }
    }
    h1,
    h2,
    h3,
    .h1,
    .h2,
    .h3{
        a{
            color: $text-color;  
        }
    }
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-top: 0px;
    margin-bottom: 40px;
    /*
    font-family: $font-family-sans-serif; 
    font-weight: 400;
    font-size: $font-size-base;
    line-height: 1;
    */
    small{
        font-size: 70%;
        font-weight: $headings-font-weight;

    }
    /*a, .btn-link{
        position: relative;
        &:after{
            content: '';
            position: absolute;
            bottom: -3px!important;
            width: 0px;
            height: 2px!important;
            background-color: transparent;
            z-index: 1;
            opacity:1; 
            left: 0;
            right: 0;
            @include transition(all ease 0.5s);
        } 
        &:hover, &:focus{
            &:after{
                width: 100%;
                background-color: lighten($main-color, 20%);
            }

        }

    }*/
}
strong{
    font-weight: 600;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        font-weight: inherit;
    }
}
.h1{
    font-size: $font-size-h1 - 8;
}
.h2{
    font-size: $font-size-h2 - 5;
}
.h3{
    font-size: $font-size-h3 - 4;
}
.h4{
    font-size: $font-size-h4 - 3;
}
.h5{
    font-size: $font-size-h5 - 2;
}
.h6{
    font-size: $font-size-h6 - 2;
}
@media (min-width: $screen-sm){
    .h1{
        font-size: $font-size-h1;
    }
    .h2{
        font-size: $font-size-h2;
    }
    .h3{
        font-size: $font-size-h3;
    }
    .h4{
        font-size: $font-size-h4;
    }
    .h5{
        font-size: $font-size-h5;
    }
    .h6{
        font-size: $font-size-h6;
    }
}

blockquote{
    padding: 30px 0 0;
    margin: 0;
    font-size: inherit;
    border-width: 0;
    color: inherit;
    position: relative;
    &:after{
        content: $fa-var-quote-right;
        font-family: FontAwesome;
        position: absolute;
        left: 0;
        top: -2px;
        font-size: 16px;
        display: block;
    }
}

.text-underline{
    text-decoration: underline;
}
.underline{
    position: relative;
    display: inline-block;
    margin-bottom: 15px;
    &:after{
        content: '';
        position: absolute;
        bottom: -15px;
        left: 0;
        right: 0;
        width: 72px;
        height: 3px;
        background-color: $text-color;
    }

    @media (max-width: 420px) {
        &{
            &:after{
                bottom: -15px;
            }
        }
    }
}
p{
    letter-spacing: 0.2px;
    font-weight: 400;
    margin-bottom: 15px;
    &:empty{
        display: none; height: 15px;
    }
    &:last-child{
        margin-bottom: 0px;
    }
}
ol,ul{
    li{
        font-weight: 400;
    }
}
.text-main{
    color: $main-color;
}
#blog-wrapper{
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        margin-top: 40px;
        margin-bottom: 30px;

    }
}