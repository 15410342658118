.blog-grid{
    .blog-item{
        position: relative;
        background-color: $blog-grid-bg;
        padding-bottom: 20px;
        margin-bottom: 30px;
        @include box-shadow($blog-grid-box-shadow);
        @include transition(all 0.35s ease-out);
        .blog-link{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 2;
        }
        .blog-image{
            position: relative;
            height: 220px;
            display: block;
            overflow: hidden;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                @include translate(-50%, -50%);
                height:220px;
                min-width:100%; 
                width: auto;
                vertical-align: middle;
                object-fit: cover;
                object-position: center;
            }
            .no-image{
                height: 220px!important;
            }
        }
        .blog-header{
            padding: 20px 15px 0;
            .blog-title{
                font-size: $blog-grid-font-size-title;
                font-weight: $blog-grid-font-weight-title;             
                margin: 0;
                a{
                    color: $blog-grid-color-title;
                }
            }
        }
        .blog-info{
            padding: 0 15px 5px;
            .blog-date{
                font-size: $blog-grid-font-size-date;
                font-weight: $blog-grid-font-weight-date;   
                color: $blog-grid-color-date; 
            }
            .blog-author{
                font-size: $blog-grid-font-size-author;
                font-weight: $blog-grid-font-weight-author;   
                color: $blog-grid-color-author;
                margin-left: 5px;
            }
            .blog-comments{
                font-size: $blog-grid-font-size-comments;
                font-weight: $blog-grid-font-weight-comments;   
                color: $blog-grid-color-comments; 
            }
        }
        .blog-content{
        }
        .blog-footer{
            padding: 0 15px;
            text-align: right;
            a{
                font-size: $blog-grid-font-size-btn;
            }
        }
        &:hover, &:focus{
            @include box-shadow($blog-grid-box-shadow-hover);
            .blog-header{
                .blog-title{
                    a{
                        color: $blog-grid-color-title-hover;
                    }
                }
            }
            .blog-footer{
                a{
                    color: $blog-grid-color-title-hover;
                }
            }
        }
    }
    .one, .three, .four, .six{
        .blog-item{
            @media (min-width: $screen-sm){
                .blog-header{
                    .blog-title{
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
    .two{
        .blog-item{
            .blog-header{
                .blog-title{
                    font-size: $blog-grid-font-size-title +1;
                }
            }
            @media (min-width: $screen-sm){
                .blog-header{
                    .blog-title{
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}
