$reviews-grid-bg: #fff;
$reviews-grid-box-shadow: 0 0 18px 0 rgba(40,20,70,0.1); 
$reviews-grid-box-shadow-hover: 0 0 18px 0 rgba(40,20,70,0.3); 
$reviews-grid-color-title: $text-color;
$reviews-grid-color-title-hover: lighten($text-color, 20%);
$reviews-grid-color-content: $text-color;
$reviews-grid-color-content-hover: lighten($text-color, 20%);
$reviews-grid-color-date: $text-color;
$reviews-grid-color-author: $text-color;
$reviews-grid-color-comments: $text-color;
$reviews-grid-font-size: 17px;
$reviews-grid-font-size-title: 21px;
$reviews-grid-font-size-btn: 13px;
$reviews-grid-font-size-date: 13px;
$reviews-grid-font-size-author: 13px;
$reviews-grid-font-size-comments: 13px;
$reviews-grid-font-weight: 400;   
$reviews-grid-font-weight-title: 500;   
$reviews-grid-font-weight-date: 300;   
$reviews-grid-font-weight-author: 300;   
$reviews-grid-font-weight-comments: 300;   

$reviews-list-bg: #fff;
$reviews-list-box-shadow: 0 0 18px 0 rgba(40,20,70,0.1); 
$reviews-list-box-shadow-hover: 0 0 18px 0 rgba(40,20,70,0.3); 
$reviews-list-color-title: $text-color;
$reviews-list-color-title-hover: lighten($text-color, 20%);
$reviews-list-color-content: $text-color;
$reviews-list-color-content-hover: lighten($text-color, 20%);
$reviews-list-color-date: $text-color;
$reviews-list-color-author: $text-color;
$reviews-list-color-comments: $text-color;
$reviews-list-font-size-title: $font-size-h2;
$reviews-list-font-size-content: 14px;
$reviews-list-font-size-btn: 14px;
$reviews-list-font-size-date: 13px;
$reviews-list-font-size-author: 13px;
$reviews-list-font-size-comments: 13px;
$reviews-list-font-weight-title: 500;   
$reviews-list-font-weight-content: 400;   
$reviews-list-font-weight-date: 300;   
$reviews-list-font-weight-author: 300;   
$reviews-list-font-weight-comments: 300;  

$reviews-popular-bg: #fff;
$reviews-popular-box-shadow: 0 0 18px 0 rgba(40,20,70,0.1); 
$reviews-popular-box-shadow-hover: 0 0 18px 0 rgba(40,20,70,0.3); 
$reviews-popular-color-title: $text-color;
$reviews-popular-color-title-hover: lighten($text-color, 20%);
$reviews-popular-color-content: $text-color;
$reviews-popular-color-content-hover: lighten($text-color, 20%);
$reviews-popular-color-date: $text-color;
$reviews-popular-color-author: $text-color;
$reviews-popular-color-comments: $text-color;
$reviews-popular-font-size-title: 18px;
$reviews-popular-font-size-content: 14px;
$reviews-popular-font-size-btn: 13px;
$reviews-popular-font-size-date: 13px;
$reviews-popular-font-size-author: 13px;
$reviews-popular-font-size-comments: 13px;
$reviews-popular-font-weight-title: 500;   
$reviews-popular-font-weight-content: 300;   
$reviews-popular-font-weight-date: 300;   
$reviews-popular-font-weight-author: 300;   
$reviews-popular-font-weight-comments: 300;   
 
$reviews-archive-color: $text-color;
$reviews-archive-color-hover: lighten($text-color, 20%);
$reviews-archive-font-size: 14px;
$reviews-archive-font-weight: 300;   
$reviews-archive-font-weight-active: 400;   