// Import functions
@import "functions";
// Import mixin
@import "mixin";
// Import variables
@import "projectsVariables";
// Import layout
.projects{
    @import "projectsOverall";
    //@import "projectsCarousel";
    @import "projectsEntry";
    @import "projectsGallery";
    @import "projectsGalleryFlip";
    @import "projectsGrid";
    //@import "projectsList";
    //@import "projectsQa";
}