.nav-pills{
    > li{
        position: relative;
        > a{
            font-size:13px;
            padding: 8px 0;
            color: $text-color;
            @include opacity(0.45);
            text-transform: uppercase;
            position: relative;
            &:hover, &:focus{
                opacity: 1;
            }
            .flag, .fa{
                margin-right: 10px;
            }
            #notifications_badge{
                position: absolute;
                right: 10px;
                top: 6px;
                .badge{
                    background-color: $gray;                    
                    &.active{                    
                        background-color: $brand-danger;
                    }
                }
            }

        }
        &.active{
            > a, > a:hover, > a:focus{
                background-color: transparent;
                color: $text-color;
                @include opacity(1);
            }
        }
        &.dropdown{
            padding-right: 40px;
            .dropdown-toggle{
                position: absolute;
                top: 0;
                right:0;
                width: 40px;
                z-index: 1;
                text-align: center;
                &:hover, > &:focus{
                    background-color: #fff;
                    @include opacity(1);
                }

            }
            .dropdown-menu{
                right: 0;                
                .btn{
                    text-align: center!important;
                }
            }
        }

    }

}
.nav-tabs{            
    margin: 40px 0 20px;
    padding: 0;
    > li{
        a{
            font-weight: 300;
            font-size: 13px;
            text-transform: uppercase;
            color: #777;
            text-shadow: 0 0 0 #777;
            outline: 0;
            padding: 9px 10px;
            opacity: 0.6;
            position: relative;
            &:hover,&:focus{
                color: #333;
                outline: 0;
                opacity: 0.9;
            }
            .flag, .fa{
                margin-right: 5px;
            }
            &.tab-section-notifications{
                //padding: 9px 35px 9px 10px;
                .section-badge{
                    position: absolute;
                    right: 7px;
                    top: 8px;
                    .badge{
                        background-color: $brand-danger;
                    }
                }
            }
        }
        &.dropdown{
            position: relative;
            padding-right: 28px;
            .dropdown-toggle{    
                position: absolute;
                top: 0px;
                right: 0px;
                border-width: 1px 1px 1px 0;
            }
            .dropdown-menu{
                padding: 0;
                margin: 0;
                border-width: 0;
                box-shadow: none;
            }
        }
        &.active{
            a{
                background-color: transparent;
                border-bottom-color: #f5f5f5;
                opacity: 1;
            }
        }
    }
    .secondary-nav {
        float: right;
        margin-left: 10px;
        margin-right: 0;
    }
}
.tab-content{
    border-width: 0;
    .tab-title{
        padding: 15px 10px;
        background-color: #fff;
        font-weight: 500;
        text-transform: uppercase;
    }
    .tab-body{        
        padding: 20px;
        background-color: #f9f9f9;
        margin-bottom: 10px;
    }
    .loading{
        text-align: center;
        padding: 20px 0;
    }
    .nav-tabs{            
        margin: 0 0 10px;
    }
    > .tab-pane{
        padding: 0;
        -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
        -moz-box-shadow:    0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
        box-shadow:         0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
        &#settings{
            padding: 0;
            margin-top: -21px;
        }
    }

    .tab-content{
        > .tab-pane{
            box-shadow: none!important;
        }
    }
}

.tab-content-notifications{
    margin: -10px 0;
} 