/// Computes a top-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function top-shadow($depth) {
    $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
    $blur: nth(1.5 3 10 14 19, $depth) * 4px;
    $color: rgba(black, nth(.12 .16 .19 .25 .30, $depth));

    @return 0 $primary-offset $blur $color;
}

/// Computes a bottom-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function bottom-shadow($depth) {
    $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
    $blur: nth(1 3 3 5 6, $depth) * 4px;
    $color: rgba(black, nth(.24 .23 .23 .22 .22, $depth));

    @return 0 $primary-offset $blur $color;
}