#sidebar{
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        margin-top: 0px;
        margin-bottom: 20px;
    }
}