.products_categories{
    .category-list{

    }
    .category-grid{
        .row{
            margin-left: -4px;
            margin-right: -4px;
            .col-xs-12{
                padding-left: 4px;
                padding-right: 4px;
            }
        }
        .category-item{
            margin-top: 8px; 
            position: relative;
            .category-image{
                background-position: center top;
                background-repeat: no-repeat;
                background-size: cover;
                width: 100%;
                display: block;
                height: 180px; 
                &:before{
                    content:'';
                    position: absolute;
                    top:0;
                    left:0;
                    right: 0;
                    bottom: 0;
                    opacity: 0;
                    @include transition(all .3s ease-in-out);
                }

            }
            .category-caption{
                position: absolute;
                z-index: 2;
                bottom: 0px;
                left:0px;
                right: 0px;
                height: 83px;
                background-color: rgba(0,0,0,.2);
                padding: 7px 20px;
                .category-header{
                    display: table;
                    height: 100%;
                    width: 100%;
                    //min-height: 68px;
                    .category-title{
                        display: table-cell;
                        height: 100%;
                        width: 100%;
                        vertical-align: middle;
                    }
                }

            }
            .category-title{
                margin-top: 0px;
                margin-bottom: 0px;                            
                font-size: 18px;
                text-transform: uppercase;
                a{
                    color: $white;
                    line-height: 1;
                    span{
                        font-weight: 700;
                        display: block;
                    }
                    &:hover,&:focus{
                        color: darken($white, 15%);
                    }
                }
            }
            @media (min-width: $screen-sm){
                .category-image{
                    height: 220px; 
                }
                .category-title{                          
                    font-size: 24px;
                } 
            }
            @media (min-width: $screen-md){
                .category-image{
                    height: 279px; 
                }
            }
            &:hover, &:focus{
                .category-image{
                    &:before{
                        z-index: 1;
                        opacity: 1;
                        background-color: rgba(0,0,0,.25);
                        @include transition(all .3s ease-in);
                    }
                }
            }
        }

    }
}
.container-fluid{
    .products_categories{
        .category-grid{
            .category-item{
                .category-image{
                    height: 279px; 
                }
                @media (min-width: $screen-sm){
                    .category-image{
                        height: 320px; 
                    }

                }
                @media (min-width: $screen-md){
                    .category-image{
                        height: 400px; 
                    }
                }
            }
        }
    }
}