.pt-xs-0{
    padding-top: 0px !important;
} 
.pl-xs-0{
    padding-left: 0px !important;
} 
.pr-xs-0{
    padding-right: 0px !important;
} 
.pb-xs-0{
    padding-bottom: 0px !important;
} 
@for $i from 1 through 20{
    .pt-xs-#{$i * 5}{
        padding-top: $i * 5 + px !important;
    } 
    .pl-xs-#{$i * 5}{
        padding-left: $i * 5 + px !important;
    } 
    .pr-xs-#{$i * 5}{
        padding-right: $i * 5 + px !important;
    } 
    .pb-xs-#{$i * 5}{
        padding-bottom: $i * 5 + px !important;
    }
}

@media (min-width: $screen-sm) {
    .pt-sm-0{
        padding-top: 0px !important;
    } 
    .pl-sm-0{
        padding-left: 0px !important;
    } 
    .pr-sm-0{
        padding-right: 0px !important;
    } 
    .pb-sm-0{
        padding-bottom: 0px !important;
    } 
    @for $i from 1 through 20{

        .pt-sm-#{$i * 5}{
            padding-top: $i * 5 + px !important;
        } 
        .pl-sm-#{$i * 5}{
            padding-left: $i * 5 + px !important;
        } 
        .pr-sm-#{$i * 5}{
            padding-right: $i * 5 + px !important;
        } 
        .pb-sm-#{$i * 5}{
            padding-bottom: $i * 5 + px !important;
        }
    }
}
@media (min-width: $screen-md) {
    .pt-md-0{
        padding-top: 0px !important;
    } 
    .pl-md-0{
        padding-left: 0px !important;
    } 
    .pr-md-0{
        padding-right: 0px !important;
    } 
    .pb-md-0{
        padding-bottom: 0px !important;
    } 
    @for $i from 1 through 20{

        .pt-md-#{$i * 5}{
            padding-top: $i * 5 + px !important;
        } 
        .pl-md-#{$i * 5}{
            padding-left: $i * 5 + px !important;
        } 
        .pr-md-#{$i * 5}{
            padding-right: $i * 5 + px !important;
        } 
        .pb-md-#{$i * 5}{
            padding-bottom: $i * 5 + px !important;
        }
    }
}
@media (min-width: $screen-lg) {
    .pt-lg-0{
        padding-top: 0px !important;
    } 
    .pl-lg-0{
        padding-left: 0px !important;
    } 
    .pr-lg-0{
        padding-right: 0px !important;
    } 
    .pb-lg-0{
        padding-bottom: 0px !important;
    } 
    @for $i from 1 through 20{
        .pt-lg-#{$i * 5}{
            padding-top: $i * 5 + px !important;
        } 
        .pl-lg-#{$i * 5}{
            padding-left: $i * 5 + px !important;
        } 
        .pr-lg-#{$i * 5}{
            padding-right: $i * 5 + px !important;
        } 
        .pb-lg-#{$i * 5}{
            padding-bottom: $i * 5 + px !important;
        }
    }
}
