// Import functions
@import "functions";
// Import mixin
@import "mixin";
// Import variables
@import "frontendVariables";
/* Cookie */
.alert-cookie{
    position:fixed;
    display:none;
    bottom:0;
    left:0;
    z-index:9999;
    width:100%;
    padding:12px 10px 5px;
    background: #1d1d1d;
    border-width: 0;
    border-radius: 0px;
    margin-bottom: 0px;
    font-size:11px!important;
    font-weight: 400;
    color:#fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    text-transform: uppercase;
    .cookie.alert-link{
        text-decoration: none;
        font-weight: 500;
        color: #fff;
        text-decoration: underline;
        &:hover, &:focus{
            color: darken(#fff, 10%);
        }
    }

    .btn-cookie-ok{
        color: #ffffff;
        font-size: 9pt;
        border-radius: 0;
        margin-top: -2px;
        padding: 3px 6px;
        background-color: $main-color;
        border-color: $main-color;
        &:hover,&:focus,&:active,&.active { 
            color: #ffffff; 
            background-color: darken($main-color, 10%); 
            border-color: darken($main-color, 10%);
        }
    }
    .btn-cookie-x{ 
        font-size:9pt;  
        color: #ffffff; 
        border-radius: 0;      
        margin-top: -2px;   
        padding: 3px 6px;
        background-color: #222;  
        border-color: #222;
        &:hover,&:focus,&:active,&.active { 
            color: #ffffff; 
            background-color: #555; 
            border-color: #777;
        }
    }
}
/* END Cookie */