.projects-gallery-flip{
    .projects-item{
        position: relative;
        margin-bottom: 30px;
        .projects-link{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 2;
        }
        .projects-images{
            position: relative;
            min-height: 350px;
            display: block;
            .image{
                position: absolute;
                height: 180px;
                margin-bottom: 20px;
                display: block;
                overflow: hidden;
                @include transition(all 0.35s ease-out);
                a{
                    display: block;
                }
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    @include translate(-50%, -50%);
                    height:180px;
                    min-width:100%; 
                    width: auto;
                    vertical-align: middle;
                    object-fit: cover;
                    object-position: center;
                    z-index: 2;
                    @include transition(all 0.35s ease-out);
                }
                .no-image{
                    height: 180px!important;
                }
                &:nth-child(4n+1){
                    width: 100%;
                    height: 320px;
                    top: 20px;
                    left: 0%;
                    img {
                        height: 320px;
                    }

                }

            }
            @media (max-width: $screen-xs-max){
                .image{
                    &:nth-child(4n+2),&:nth-child(4n+3), &:nth-child(4n+4){
                        width: 1px;
                        height: 1px;
                        padding: 0;
                        margin: -1px;
                        overflow: hidden;
                        clip: rect(0,0,0,0);
                        border: 0;

                    } 
                }
            }            

            @media (min-width: $screen-sm){
                min-height: 670px;
                margin-top: 20px;
                .image{
                    &:nth-child(4n+1){
                        width: 30%;
                        height: 200px;
                        top: 20px;
                        left: calc(40% + 20px);
                        img {
                            height: 200px;
                        }

                    }
                    &:nth-child(4n+2){
                        width: 50%;
                        height: 400px;
                        top: 240px;
                        left: calc(40% + 20px);
                        img {
                            height: 400px;
                        }

                    } 
                    &:nth-child(4n+3){
                        width: 30%;
                        height: 275px;
                        top: 150px;
                        left: 10%;
                        img {
                            height: 275px;
                        }

                    } 
                    &:nth-child(4n+4){
                        width: 40%;
                        height: 180px;
                        top: 445px;
                        left: 0%;
                        img {
                            height: 180px;
                        }
                    } 
                } 
            }
            @media (min-width: $screen-md){
                min-height: 670px;
                .image{
                    &:nth-child(4n+1){
                        width: 30%;
                        height: 200px;
                        top: 20px;
                        left: calc(30% + 20px);
                        img {
                            height: 200px;
                        }

                    }
                    &:nth-child(4n+2){
                        width: 60%;
                        height: 400px;
                        top: 240px;
                        left: calc(30% + 20px);
                        img {
                            height: 400px;
                        }

                    } 
                    &:nth-child(4n+3){
                        width: 20%;
                        height: 275px;
                        top: 120px;
                        left: 10%;
                        img {
                            height: 275px;
                        }

                    } 
                    &:nth-child(4n+4){
                        width: 25%;
                        height: 200px;
                        top: 415px;
                        left: 5%;
                        img {
                            height: 200px;
                        }

                    } 
                } 
            }
        }
        .projects-header{
            position: absolute;
            width:  100%;
            text-align: left;
            padding: 20px;
            bottom: 10px;
            text-align: left;
            z-index: 3;
            background-color: rgba(0,0,0,.25);
            @include transition(all 0.35s ease-out);
            .projects-title{
                font-size: $projects-gallery-font-size-title;
                font-weight: 500;                
                margin: 0;
                a{
                    color: $white;
                    small{
                        margin-top: 5px;
                        display: block;
                        color: $white;
                    }
                }
            }

            @media (min-width: $screen-sm){
                &{
                    background-color: transparent;
                    width: 43%;
                    text-align: right;
                    top: 88px;
                    bottom: auto;
                    left: 0%;
                    .projects-title{
                        a{
                            color: $projects-gallery-color-title;
                        }
                    }
                }
            }
            @media (min-width: $screen-md){
                &{
                    top: 178px;
                    width: 40%;
                    left: 62%;
                    text-align: left;
                }
            }
        }
        .projects-info{
        }
        .projects-content{
        }
        .projects-footer{
            padding: 0 15px 20px;
            text-align: right;
            a{
                font-size: $projects-gallery-font-size-btn;
            }
        }
        &:hover, &:focus{
            .projects-header{
                opacity: 0;
            }
            .projects-footer{
                a{
                    color: $projects-gallery-color-title-hover;
                }
            }

            @media (min-width: $screen-sm){
                .projects-header{
                    opacity: 1;
                    .projects-title{
                        a{
                            color: $projects-gallery-color-title-hover;
                        }
                    }
                    a{
                        color: $projects-gallery-color-title-hover;
                    }
                }

            }
        }        
    }
}
