/* navbar-cms-sidebar */
.navbar-cms-sidebar{
    background-color: #FFFFFF;
    text-transform: lowercase;
    padding: 0;
    margin: -15px;
    list-style: none;
    .arrow-container {
        position:absolute;
        top:0;
        bottom:0;
        right:0;
        width:30px;
        background-color: transparent;
        line-height: 2.229;
        .arrow {
            vertical-align: middle;
            padding-left:11px;
            font-size:14pt;
            color: $main-color;
        }
        > .fa.arrow:before {
            content: "\f105";
        }
    }
    .active > a > .arrow-container > .fa.arrow:before,
        a.active > .arrow-container > .fa.arrow:before{
        content: "\f107";
    }
    ul{
        padding: 0;
        margin:0;
        a{
            padding: 8px 30px 8px 30px;
            background-color: #ffffff;
            &:hover, &:focus, &:active, &.active {
                color: $main-color;
                text-decoration: none;
            }
            ul{
                a{
                    padding: 8px 30px 8px 45px;
                    background-color: #ffffff;
                    &:hover, &:focus, &:active, &.active {
                        color: $main-color;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .collapse {
        display: none;
        padding: 0;
        margin:0;
        &.in {
            display: block;
            padding: 0;
            margin:0;
        }
    }
    li, a {
        display: block;
    }
    a{
        font-size:11pt;
        padding: 8px 30px 8px 20px;
        color: #505050;
        border-bottom: 1px solid #F6F6F6;
        position: relative;
        &:hover, &:focus, &:active, &.active {
            color: $main-color;
            text-decoration: none;
        }
    }
}
/* end navbar-cms-sidebar */