$user_colors: (
c1: #1abc9c,
c2: #2ecc71,
c3: #3498db,
c4: #9b59b6,
c5: #34495e,
c6: #f1c40f,
c7: #e67e22,
c8: #e74c3c,
c9: #2980b9,
c10: #8e44ad,
);