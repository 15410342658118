.navbar-list{
    ul{
        li{
            margin-bottom: 10px;
            a{
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 1px;
            }
        }
    }
}
footer{
    .navbar-list{
        ul{
            margin: 0;
            padding: 0;
            li{
                list-style: none;
                a{
                    color: $footer-link-color;
                    text-shadow: $footer-link-color 0 0 0;
                }
            }
        }

    }
}