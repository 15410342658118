.pagin { margin-bottom:15px; }
.pagin label{ font-weight: normal; margin-right:10px; font-size:9pt; }
.pagin .products-category-title{ margin:0;  font-size:12pt; font-weight: bold; color:#1F365E; text-transform: uppercase; line-height: 1em;  padding: 10px 0; }
.pagin .pagination-sm,
.pagin-bottom .pagination-sm{ max-height: 30px; margin:0 0 -3px; }
.pagin .pagination-sm{ margin-left:20px; }
.pagin-bottom{ 
    margin: 20px 0  0;
    text-align: center;
}
.pagination-sm > li:first-child > a, .pagination-sm > li:first-child > span {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}
.pagination-sm > li:last-child > a, .pagination-sm > li:last-child > span {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    background-color: $main-color;
    border-color: #ddd;
    color: $white;
    font-weight: bold;
    cursor: default;
    z-index: 2;
}
.pagination > li > a, .pagination > li > span {
    background-color: #fff;
    border: 0px solid #ddd;
    color: #777;
    float: left;
    line-height: 1.42857;
    margin-left: -1px;
    padding: 6px 12px;
    position: relative;
    text-decoration: none;
    cursor: pointer;
}