/* carousel */
.carousel-fade .carousel-inner .item {opacity: 0; transition-property: opacity;}
.carousel-fade .carousel-inner .active {opacity: 1;}
.carousel-fade .carousel-inner .active.left,.carousel-fade .carousel-inner .active.right {  left: 0;  opacity: 0;  z-index: 1;}
.carousel-fade .carousel-inner .next.left,.carousel-fade .carousel-inner .prev.right { opacity: 1;}
.carousel-fade .carousel-control { z-index: 2;}
@media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .item.next,.carousel-fade .carousel-inner > .item.active.right {opacity: 0; -webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}
    .carousel-fade .carousel-inner > .item.prev, .carousel-fade .carousel-inner > .item.active.left { opacity: 0; -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }
    .carousel-fade .carousel-inner > .item.next.left, .carousel-fade .carousel-inner > .item.prev.right,
    .carousel-fade .carousel-inner > .item.active { opacity: 1; -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }
}

.carousel-inner > .item-bg {
    color: #ffffff;
    background-color: $main-color;
}
.carousel-inner > .item{
    .carousel-table{
        width: 100%;
        min-height: 100%;
        display:table;
        height:inherit;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba(0,0,0,.15);
        z-index: 1;
        .carousel-row{
            width: 100%;
            height: 100%;
            display:table-row;
            .carousel-cell{
                display:table-cell;
                width: 100%;
                height: 100%;
                vertical-align:middle;
                padding: 0px;
                text-align: left;
                &.image{
                    width: 77%;
                }
                &.description{
                    width: 23%;
                }

                @media (max-width: 991px){
                    &.image{
                        float: left;
                        width: 100%;
                    }
                    &.description{
                        float: left;
                        width: 100%;
                    }
                }

            }
        }
    }
    .carousel-caption{
        padding: 0;
        margin: 0;
        left: 50%;
        //top: 50%;
        //top: 220px;
        padding-left: 20px;
        padding-right: 20px;
        bottom: 60px;
        @include transform(translate(-50%));
        text-shadow: none;
    }
    .carousel-title{
        padding: 0 0 0 6px;
        font-family: $font-family-serif;
        font-style: italic;
        font-size: 20px;
        font-weight: 400;
        text-align: left;
        text-transform: lowercase;
        letter-spacing: 1px;
        line-height: 1.1;
        animation-delay: 0.5s;
        text-shadow: 1px 2px 2px rgba(0,0,0,.45);
    }
    .carousel-description{
        padding: 0;
        text-align: left;
        font-weight: 600;
        font-size: 28px;
        text-transform: uppercase;
        text-shadow: 2px 4px 3px rgba(0,0,0,0.3);

        line-height: 1.05;
        letter-spacing: 2px;
        h1,h2,h3,h4{
            font-weight: 600;
            text-transform: uppercase;
            text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
            letter-spacing: 1;
        }
        .carousel-content{
            animation-delay: 1.5s;
        }
        ul{
            font-size: 14px;
            margin:30px 0 0;
            padding: 0;
            list-style: none;
            > li{
                margin-bottom: 4px;
            }
        }
        .btn{
            margin-top: 10px;
            font-size: 11px;
            font-weight: 500;
            text-transform: uppercase;
            border-width: 1px;
            animation-delay: 2.5s;
            text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
        }
    } 
    .carousel-serif{
        .carousel-description{
            font-family: $font-family-serif;
            font-size: 26px;
            font-weight: 400;
            line-height: 1.3;
            letter-spacing: 2px; 
        }
    }
    .carousel-link{
        position: absolute;
        z-index: 11;
        top:0;
        left:0;
        right: 0;
        bottom:0;
    }
    &.item-link{
        &:hover, &:focus{
            img{
                &:before{
                    z-index: 1;
                    opacity: 1;
                    background-color: rgba(0,0,0,.25);
                    @include transition(all .2s ease-in);
                }
            }
        }
    }
    .img-logo{
        margin-top: 60px;
        width: 150px;
        display: block;
    }
    .carousel-caption{
        width: 100%;
    }
    @media (min-width: 420px){
        .carousel-description{
            font-size: 38px;
            .btn{                
                font-size: 11px;
            }
        } 
    } 
    @media (max-width: $screen-xs-max){        
        .carousel-description{
            .btn{                
                padding: 8px 12px;
            }
        } 
    }
    @media (min-width: $screen-sm){
        .carousel-caption{
            width:($container-sm - $grid-gutter-width);
            padding-left: 0px;
            padding-right: 0px;
        } 
        .carousel-title{
            font-size: 30px;
        }
        .carousel-description{
            font-size: 55px;
            ul{
                font-size: 18px;
            }
            .btn{
                font-size: 14px;
                font-weight: 500;
                border-width: 1px;
            }
        } 
        .carousel-serif{
            .carousel-description{
                font-size: 36px;

            }
        }
        .img-logo{
            margin-top: 135px;
            width: auto;
        }
    }
    @media (min-width: $screen-md){    
        .carousel-caption{
            width: ($container-md - $grid-gutter-width);
        }    
        .carousel-serif{
            .carousel-description{
                font-size: 46px;

            }
        }
    }
    @media (min-width: $screen-lg){    
        .carousel-caption{
            width: ($container-lg - $grid-gutter-width);
        }  
    }


}
.carousel-xs,
.carousel-sm,
.carousel-md,
.carousel-lg,
.carousel-hero{
    .carousel-inner{
        > .item{
            .image{
                position: relative;
                height: auto;
                img{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: auto;
                    min-width:100%;
                    width: auto;
                    vertical-align: middle;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }
}
.carousel-xs {
    .carousel-inner{
        > .item{
            .image{
                height: 200px;
                img{
                    height: 200px;
                }

                @media (min-width: $screen-sm){
                    height: 250px;
                    img{
                        height: 250px;
                    }
                }
            }
        }
    }
}
.carousel-sm {
    .carousel-inner{
        > .item{
            .image{
                height: 200px;
                img{
                    height: 200px;
                }

                @media (min-width: $screen-sm){
                    height: 300px
                    img{
                        height: 300px;
                    }
                }
                @media (min-width: $screen-md){  
                    height: 400px;
                    img{
                        height: 400px;
                    }
                }
            }
        }
    }
}
.carousel-md {
    .carousel-inner{
        > .item{
            .image{
                height: 450px;
                img{
                    height: 450px;
                }
                @media (min-width: $screen-sm){
                    height: 650px;
                    img{
                        height: 650px;
                    } 
                }
                @media (min-width: $screen-md){ 
                    height: 750px;
                    img{
                        height: 750px;
                    }
                }
            }
        }
    }
}
.carousel-lg {
    .carousel-inner{
        > .item{
            .image{
                height: 400px;
                img{
                    height: 400px;
                }
                @media (min-width: $screen-sm){
                    height: 700px;
                    img{
                        height: 700px;
                    }
                }
                @media (min-width: $screen-md){    
                    height: 900px;
                    img{
                        height: 900px;
                    }
                }
            }
        }
    }
}
.carousel-hero {
    .carousel-inner{
        > .item{
            .image{
                height: 200px;
                img{
                    height: 200px;
                }
                @media (min-width: $screen-sm){
                    height: 320px;
                    img{
                        height: 320px;
                    } 
                }
                @media (min-width: $screen-md){    
                    height: 440px;
                    img{
                        height: 440px;
                    }
                }
                @media (min-width: $screen-lg){    
                    height: 560px;
                    img{
                        height: 560px;
                    }
                }
            }
        }
    }
}
.carousel-indicators {
    bottom: 10px;
    .active{
        background-color: $white;
        border-color: $white;
        //height: 12px !important;
    }
    li{
        background-color: rgba(255,255,255,.45);
        border-color: transparent;
    }

    @media (max-width: $screen-sm-max){
        &{
            bottom: 0;
        }
    }
}
.carousel-control {
    width: 6%;
    color: #ededed;
    opacity: 0.4;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;

    .fa{
        width: 40px;
        height: 40px;
        margin-top: -10px;
        font-size: 40px;
        position: absolute;
        top: 50%;
        z-index: 5;
        display: inline-block;
    }
    &.left{
        //left: -50px;
        background-image: none;
        &:hover, &:focus {
            opacity: 0.9;
        }
        .fa{
            left: 50%;
            //margin-left: -10px;
        }
    }
    &.right{
        //right: -50px;
        background-image: none;
        &:hover, &:focus {
            opacity: 0.9;
        }
        .fa{
            right: 50%;
            //margin-right: -10px;
        }
    }
}

.carousel-cover{position:fixed; width:100%;top:0; z-index:0;}
.carousel-cover.carousel,
.carousel-cover .item,
.carousel-cover .active {
    height: 100%;
}
.carousel-cover .carousel-inner {
    height: 100%;
}
.carousel-cover.carousel .carousel-3 {text-align: left;left: 10%;right: 10%; text-shadow: 1px 1px 1px rgba(0, 0, 0, .9);}
.carousel-cover .carousel-control { opacity: 0; -webkit-transition: all 1s ease; -moz-transition: all 1s ease; -ms-transition: all 1s ease; -o-transition: all 1s ease; transition: all 1s ease;}
.carousel-cover .carousel-control.left:hover { opacity: 0.5;}
.carousel-cover .carousel-control.right:hover { opacity: 0.5; }

/* end-carousel */
