.navbar-grid{
    ul{
        margin-left: -4px;
        margin-right: -4px;
        margin-top: 0;
        margin-bottom:0;
        padding: 0;
        .col{
            list-style: none;
            position: relative;
            padding-left: 4px;
            padding-right: 4px;
            .navbar-item{
                margin-top: 8px; 
                position: relative;
                -webkit-transform: perspective(1px) translateZ(0);
                transform: perspective(1px) translateZ(0);
                box-shadow: 0 0 1px transparent;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                .navbar-link{
                    display: block;
                    position: absolute;
                    top:0;
                    left:0;
                    right: 0;
                    bottom: 0;
                    z-index: 3;
                }
                .navbar-image{
                    background-position: center top;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    display: block;
                    height: 300px; 
                    z-index: 1;
                    &:before{
                        content:'';
                        position: absolute;
                        top:0;
                        left:0;
                        right: 0;
                        bottom: 0;
                        opacity: 0;
                        @include transition(all .2s ease-in-out);
                    }
                }
                .navbar-caption{
                    position: absolute;
                    z-index: 2;
                    bottom: 0px;
                    left:0px;
                    right: 0px;
                    padding: 20px 25px;
                    color: $white;
                    font-size: 20px;
                    text-transform: uppercase;
                    line-height: 1.1;
                    .navbar-icon{
                        margin-bottom: 0px;
                    }
                    span{
                        display: block;
                        clear: both;
                        font-weight: 700;
                    }
                }

                &:hover, &:focus{
                    -webkit-transform: scale(1.025);
                    transform: scale(1.025);
                    .navbar-image{
                        &:before{
                            z-index: 1;
                            opacity: 1;
                            background-color: rgba(0,0,0,.25);
                            @include transition(all .2s ease-in);
                        }
                    }
                }

                @media (min-width: $screen-sm){
                    .navbar-image{
                        height: 356px; 
                    }
                    .navbar-caption{     
                        font-size: 24px;
                    } 
                }
                @media (min-width: $screen-md){
                    .navbar-image{
                        height: 280px; 
                    }
                }
            }
        }
    }
}