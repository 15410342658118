/* navbar-cms */
.navbar-cms {
    font-family: $font-family-sans-serif; 
    background-color: $navbar-cms;
    border: 0px solid $navbar-cms;
    border-radius: 0px;
    margin-bottom: 0px;
    font-weight: 400;
    text-transform: uppercase;
    position: relative;
    z-index:100;   

    .navbar-header {
        position: relative;
        z-index: 5;
    }
    .navbar-collapse {
        z-index: 2;
        position: relative;
        padding-left: 0px;
        padding-right: 0px;
    }
    .navbar-right{
        margin-right: 0;
    }
    .navbar-text {
        color: $navbar-cms-a;
    }
    .navbar-nav {
        > li {
            float: none;
            position: initial;
            > a{
                font-size: $navbar-cms-font-size;
                color: $navbar-cms-a;
                padding-left: $navbar-padding-horizontal;
                padding-right: $navbar-padding-horizontal;
                position:relative;        
                @include transition(none);      
                &:after{
                    content: '';
                    position: absolute;
                    bottom: 0px;
                    width: calc(100% - #{$navbar-padding-horizontal-border});
                    height: 2px;
                    background-color: transparent;
                    z-index: 1;
                    opacity:1; 
                    left: $navbar-padding-horizontal;
                    right: $navbar-padding-horizontal;
                    visibility: hidden;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transition: all 0.3s ease-in-out 0s;
                    transition: all 0.3s ease-in-out 0s;
                }      
                &:hover, &:focus{
                    color: $navbar-cms-a-hover;
                    background-color: $navbar-cms-a-bg-hover;
                    &:after{
                        visibility: visible;
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);
                        background-color: lighten($navbar-cms-a, 5%);
                    }
                }

            }
            .btn-group{
                display: block;
                position: relative;
                vertical-align: middle;
                margin: auto;
                > a{
                    font-size: $navbar-cms-font-size;
                    color: $navbar-cms-a;
                    line-height: 24px;
                    padding: $navbar-padding-vertical $navbar-padding-horizontal;
                    display: block;
                    position:relative;  
                    @include transition(none);      
                    &:after{
                        content: '';
                        position: absolute;
                        bottom: 0px;
                        width: calc(100% - #{$navbar-padding-horizontal-border});
                        height: 2px;
                        background-color: transparent;
                        z-index: 1;
                        opacity:1; 
                        left: $navbar-padding-horizontal;
                        right: $navbar-padding-horizontal;
                    }      
                    &:hover, &:focus{
                        color: $navbar-cms-a-hover;
                        background-color: $navbar-cms-a-bg-hover;
                        &:after{
                            background-color: lighten($navbar-cms-a, 5%);
                            visibility: visible;
                            -webkit-transform: scaleX(1);
                            transform: scaleX(1);
                        }
                    }
                    .avatar-xs{
                        margin-right: 5px;
                        top: -3px;
                        position: relative;
                        .initials{
                            top: 4px;
                        }
                    }
                }
                .dropdown-toggle{
                    color: $navbar-cms-a;
                    background-color: transparent;
                    position: absolute;
                    display: none;
                    padding: 10px 16px 11px;
                    top: 3px;
                    right:16px;
                    z-index: 1;
                    .caret{
                        border-width: 5px;
                    }
                }
                &:hover, &:focus{
                    > a, .dropdown-toggle{
                        color: lighten($navbar-cms-a-hover, 30%);
                        background-color: transparent;
                    }
                }
                &.open{
                    .dropdown-toggle{
                        color: lighten($navbar-cms-a-hover, 30%);
                        box-shadow: none;

                        .caret{
                            border-top-width: 0px;
                            border-bottom: 5px solid;
                        }
                    }
                }

            }
            .dropdown-menu{
                margin-top: 0;
                padding-top: 0px;
                padding-bottom: 0px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-top: 0px solid $navbar-cms-a-bg-hover;  
                border-width: 0px;
                background-color: #f5f5f5;
                box-shadow: none;
                border-radius: 0;
                min-width: 120px;
                z-index: 10000;
                .dropdown-menu{
                    background-color: transparent;
                }
            }
            @media (min-width:$screen-md) {
                float: left;
                .dropdown-menu{
                    @include transition(all 0.3s);
                    opacity: 0;
                }
                &:hover{
                    .dropdown-menu{
                        display: block;
                        opacity: 1;
                    }
                }
                &:last-child{
                    a{
                        padding-right: 0px;
                    }
                    .dropdown-menu{
                        > li{
                            a{
                                padding-right: 12px;
                            }
                        }
                    }

                    > a{
                        &:after{
                            width: calc(100% - #{$navbar-padding-horizontal});
                        }

                    }

                    .btn-group{
                        > a{   
                            &:after{
                                width: calc(100% - #{$navbar-padding-horizontal});
                            }
                        }
                    }
                }
            }
            &.user-menu{
                .btn-group{
                    a{
                        padding: 13px 0px 4px 16px;
                    }
                }
            }

            &.navbar-user-menu{
                position: relative;
                > a{
                    &:after{
                        height:0; 
                    }
                    &:hover, &:focus{
                        &:after{
                            opacity:0; 
                        }
                    }
                }
                .dropdown-menu{
                    top: $navbar-padding-vertical + 40px;
                    @include box-shadow($main-shadow-small);
                    &:before{
                        content: '';
                        width: 0; 
                        height: 0; 
                        border-left: 24px solid transparent;
                        border-right: 6px solid transparent;
                        border-bottom: 12px solid #f5f5f5;
                        position: absolute;
                        top: -12px;
                        right: 0;
                    }
                    li{
                        &.user-avatar{
                            padding-top: 10px;
                            text-align: center;
                            img{
                                margin: 0 auto;
                                border-radius: 50%;
                                border: 2px solid #fff;
                            }
                            .avatar{
                                border: 2px solid #fff;
                                .initials{
                                    top: 26px;
                                }
                            }
                        }
                        &.user-info{
                            text-align: center;
                            color: #444;
                            font-size: 13px;
                            padding: 15px 20px 8px;
                            span{
                                color: #777;
                                display: block;
                                font-size: 12px;
                                line-height: 1.42857;
                                padding: 3px 20px;
                                white-space: nowrap;
                                width: 100%;
                                position: relative;
                            }
                        }
                    }
                }
            }
            &.active, &{
                > a.home{
                    padding-top: $navbar-padding-vertical-home;
                    padding-bottom: $navbar-padding-vertical-home;
                }
            }
        }
        .active{
            .btn-group{
                > a{
                    font-weight: 400;
                    background-color: transparent;
                    &:after{                 

                        visibility: visible;
                        -webkit-transform: scaleX(1);
                        transform: scaleX(1);             
                        background-color: lighten($navbar-cms-a, 5%);                      
                        opacity:1; 
                        left: $navbar-padding-horizontal;
                        right: $navbar-padding-horizontal;
                    }
                }
                .dropdown-toggle{
                    color: $navbar-cms-a-hover;
                    background-color: transparent;
                }

            }
            > a{
                color: $navbar-cms-a-hover;
                font-weight: 500;
                background-color: transparent;
                &:after{
                    visibility: visible;
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                    background-color: lighten($navbar-cms-a, 5%);
                    opacity:1; 
                    left: $navbar-padding-horizontal;
                    right: $navbar-padding-horizontal;
                }
                &:hover, &:focus{
                    color: $navbar-cms-a-hover;
                    background-color: transparent;
                }
            }
            &.navbar-user-menu{
                > a{
                    &:after{
                        opacity:0; 
                    }
                }

            }
            /*@media (min-width:$screen-md) {
                &:last-child{
                    > a{
                        &:after{
                            height: 2px;
                        }

                    }

                    .btn-group{
                        > a{   
                            &:after{
                                height: 2px;
                            }
                        }
                    }
                }
            }*/
        }
        .open{
            > a{
                color: $navbar-cms-a;
                background-color: transparent;
                &:hover, &:focus{
                    color: $navbar-cms-a-hover;
                    background-color: transparent;
                }
            }
        }        
    }
    .navbar-toggle {
        margin-top: 33px;
        margin-bottom: 3px;
        margin-right: 0;
        border-color: transparent;
        padding: 14px 14px;
        position: relative;
        &.collapsed {
            .icon-bar {
                background-color: $navbar-cms-a;
                &:nth-of-type(2) {
                    @include rotatew(0deg);
                    top: 0;
                }
                &:nth-of-type(3) {
                    opacity: 1;
                    width: 100%;
                    margin-left: 0;
                }
                &:nth-of-type(4) {
                    @include rotatew(0deg);
                    bottom: 0;
                }
            }
        }
        .icon-bar {
            width: 30px;
            height: 4px;
            position: relative;
            @include transition(all 500ms ease-in-out);
            background-color: $navbar-cms-a;

            &:nth-of-type(2) {
                @include rotatew(45deg);
                top: 9px;
            }
            &:nth-of-type(3) {
                opacity: 0;
                width: 0;
                margin-left: 50%;
            }
            &:nth-of-type(4) {
                @include rotatew(-45deg);
                bottom: 6px;
            }
        }        
    }
    .navbar-collapse{
        margin-top: 0px;
        border-width:0;
        box-shadow: none;

    }
    .navbar-link {
        color: $navbar-cms-a;
        &:hover, &:focus{
            background-color: transparent;
        }
    }
    .dropdown-menu{
        > li {
            > a{
                font-size: 12px;
                padding: 8px 19px 9px 36px;
                font-weight: 400;
                color: $navbar-cms-dropdown-a;
                &:hover, &:focus{
                    color: $navbar-cms-dropdown-a-hover;
                    background-color: $navbar-cms-dropdown-a-bg-hover;
                }

                &:after{
                    display: none;
                }

                @media (min-width:$screen-md) {
                    padding: 10px 12px;

                }
            }
            &.active{
                > a{
                    color: $navbar-cms-a-active;
                    background-color: transparent;
                }
            }
            &.divider{
                margin: 0;
            }
        }
    }
    &#navbar-main{
        top: 0;
        position: fixed;
        right: 0;
        left: 0;
        z-index: 1030;
        @include transition(all 0.3s ease-out);
        &.navbar-color{
            background-color: $navbar-cms-color;
            @include box-shadow(0 0 21px 0px rgba(47,24,71,0.1));
        }
        .navbar-brand {
            font-family: $font-family-navbar; 
            margin: 0;
            width: 230px;
            padding: 25px 0 0;
            margin-left: 0!important;
            font-size: $navbar-cms-font-size-brand - 6;
            color: $navbar-cms-a-hover;
            text-align: center;
            span{
                font-weight: 400;
                padding-top: 15px;
                display: block;
            }
            small{
                font-weight: 400;
                display: block;
                font-size: $navbar-cms-font-size-brand-small - 4;
                letter-spacing: 1px;
                margin-top: 5px;

            }
            &:hover, &:focus{
                color: $navbar-cms-a;
            }
            .img{
                margin-top: 10px;
                margin-right: 15px;
                height: 50px;
                width: 50px;
                float: left;
            }

            @media (min-width: 359px) {
                width: 260px;
                font-size: $navbar-cms-font-size-brand - 2;                
                small{
                    font-size: $navbar-cms-font-size-brand-small - 2;

                }
                .img{
                    margin-top: 5px;
                    height: 60px;
                    width: 60px;
                }
            }
            @media (min-width:$screen-sm) {
                width: 280px;
                font-size: $navbar-cms-font-size-brand;                
                small{
                    font-size: $navbar-cms-font-size-brand-small;

                }
                .img{
                    margin-top: 0;
                    height: 70px;
                    width: 70px;
                }
            }

        }
        .navbar-nav {
            > li {
                font-weight: 400;
                > a{
                    letter-spacing: 0.8px;
                    &.home{
                        padding-top: $navbar-padding-vertical -2;
                        padding-bottom: $navbar-padding-vertical -2;
                    }
                }         

            }

            &.navbar-lang{
                > li {
                    position: relative;
                    > a{
                        font-size: $navbar-lang-font-size;
                        color: lighten($navbar-cms-a, 20%);
                        padding: 10px 14px;
                        letter-spacing: 0; 
                        &:hover, &:focus{
                            color: lighten($navbar-cms-a, 10%);
                        }
                        &:after{
                            height: 0px;
                        } 

                    }
                    &.active{
                        >a{
                            font-weight: 500;
                            color: lighten($navbar-cms-a-hover, 20%);
                        }
                    }

                    &:last-child{
                        > a{
                            padding-right: 0px;
                        }
                    }
                }
            }

        }

        @media (min-width:$screen-md) {
            .navbar-nav {
                padding-top: ($navbar-padding-vertical * 2) - 12;
                &.navbar-lang{
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 2;
                    padding: 0;
                }
                .navbar-user-menu{
                    a {
                        span{
                            position: absolute;
                            width: 1px;
                            height: 1px;
                            padding: 0;
                            margin: -1px;
                            overflow: hidden;
                            clip: rect(0,0,0,0);
                            border: 0;
                        }
                    }
                }
            }
        }

    }
    &.navbar-user{
        z-index: 99;
        min-height: 64px;
        .navbar-brand{
            height: 64px;
        }
        .navbar-toggle{
            margin-top: 10px;
        }
        .navbar-nav {
            > li {
                > a{
                    font-size: 14px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                }

                > .btn-group{
                    > a{
                        font-size: 14px;
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }

                }

            }
        }
    }

    @media (min-width:$screen-md) {
        .navbar-collapse{
            .navbar-form {

                .input-group{
                    position: relative;
                    left: 20px;
                }
            }
        }
        .navbar-nav {
            > li {
                > a{
                }
                &:last-child{
                    > a{
                        padding-right: 0px;
                    }
                }

            }
        }
    }
    @media (max-width: $screen-sm-max) {
        &{
            .navbar-brand{
                color: $navbar-cms-a;
            }
            .navbar-nav {
                margin: 0px;
                display: block;
                width: 100%;
                text-align: center;
                > li{
                    > a{
                        color: $navbar-cms-a;
                    }
                }
                .btn-group{
                    display: block;
                    a{                        
                        padding: 8px 70px 9px 16px !important;
                        color: $navbar-cms-a;
                    }
                    .dropdown-toggle{

                        display: block !important;
                        border-width: 0;
                        color: $navbar-cms-a-hover;
                        background-color: $white;
                        &:hover, &:focus{
                            color: $white;
                            background-color: $main-color;
                        }
                    }
                }
                .open{
                    .dropdown-menu{
                        > li{
                            > a{
                                color: $navbar-cms-a;
                                background-color: $navbar-cms-a-bg;
                                &:hover, &:focus{

                                    color: $navbar-cms-a-hover;
                                    background-color: darken($navbar-cms-a-bg-hover, 5%);
                                }
                            }
                        }
                        > .active{
                            > a{
                                color: $navbar-cms-a;
                                background-color: darken($navbar-cms-a-bg-hover, 5%);
                                &:hover, &:focus{
                                    color: $navbar-cms-a-hover;
                                    background-color: darken($navbar-cms-a-bg-hover, 5%);
                                }
                            }
                        }
                    }
                }
                &.navbar-lang{
                    background-color: #f9f9f9;
                    >li{
                        display: inline-block;
                    }
                }
            }
        }
    }

    @media (max-width: $screen-sm-max) {
        .navbar-header {
            float: none;
            margin:0;
        }
        .navbar-toggle {
            display: block;
        }
        .navbar-collapse.collapse {
            display: none!important;
        }
        .navbar-collapse.collapse.in { 
            display: block!important;
            max-height: 300px;
        }
        .collapsing {
            overflow: hidden!important;
        }


    }
}

/*
@media (min-width:$screen-md) {
    .navbar-social{
        background-color: transparent;
        position: absolute;
        top: 5px;
        right: 15px;
        margin: 0;
        padding: 0;
    }
    .navbar-user-menu{
        background-color: transparent;
        position: absolute;
        top: 5px;
        right: 90px;
        margin: 0;
        padding: 0;
    }
    .navbar-lang{
        background-color: transparent;
        position: absolute;
        top: 9px;
        right: 0;
        margin: 0;
        padding: 0;
    }
}*/