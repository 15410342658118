.mt-xs-0{
    margin-top: 0px;
} 
.mb-xs-0{
    margin-bottom: 0px;
} 
.ml-xs-0{
    margin-left: 0px;
} 
.mr-xs-0{
    margin-right: 0px;
} 
@for $i from 1 through 32{
    .mt-xs-#{$i * 5}{
        margin-top: $i * 5 + px;
    } 
    .mb-xs-#{$i * 5}{
        margin-bottom: $i * 5 + px;
    }
    .ml-xs-#{$i * 5}{
        margin-left: $i * 5 + px;
    } 
    .mr-xs-#{$i * 5}{
        margin-right: $i * 5 + px;
    }
    .mt-xs-m#{$i * 5}{
        margin-top: -($i * 5) + px;
    } 
    .mb-xs-m#{$i * 5}{
        margin-bottom: -($i * 5) + px;
    }
    .ml-xs-m#{$i * 5}{
        margin-left: -($i * 5) + px;
    } 
    .mr-xs-m#{$i * 5}{
        margin-right:  -($i * 5) + px;
    }
}

@media (min-width: $screen-sm) {
    .mt-sm-0{
        margin-top: 0px;
    } 
    .mb-sm-0{
        margin-bottom: 0px;
    }
    .ml-sm-0{
        margin-left: 0px;
    } 
    .mr-sm-0{
        margin-right: 0px;
    }
    @for $i from 1 through 32{
        .mt-sm-#{$i * 5}{
            margin-top: $i * 5 + px;
        } 
        .mb-sm-#{$i * 5}{
            margin-bottom: $i * 5 + px;
        }
        .ml-sm-#{$i * 5}{
            margin-left: $i * 5 + px;
        } 
        .mr-sm-#{$i * 5}{
            margin-right: $i * 5 + px;
        }
        .mt-sm-m#{$i * 5}{
            margin-top: -($i * 5) + px;
        } 
        .mb-sm-m#{$i * 5}{
            margin-bottom: -($i * 5) + px;
        }
        .ml-sm-m#{$i * 5}{
            margin-left: -($i * 5) + px;
        } 
        .mr-sm-m#{$i * 5}{
            margin-right:  -($i * 5) + px;
        }
    }
}
@media (min-width: $screen-md) {
    .mt-md-0{
        margin-top: 0px;
    } 
    .mb-md-0{
        margin-bottom: 0px;
    }
    .ml-md-0{
        margin-left: 0px;
    } 
    .mr-md-0{
        margin-right: 0px;
    }
    @for $i from 1 through 32{
        .mt-md-#{$i * 5}{
            margin-top: $i * 5 + px;
        } 
        .mb-md-#{$i * 5}{
            margin-bottom: $i * 5 + px;
        }
        .ml-md-#{$i * 5}{
            margin-left: $i * 5 + px;
        } 
        .mr-md-#{$i * 5}{
            margin-right: $i * 5 + px;
        }
        .mt-md-m#{$i * 5}{
            margin-top: -($i * 5) + px;
        } 
        .mb-md-m#{$i * 5}{
            margin-bottom: -($i * 5) + px;
        }
        .ml-md-m#{$i * 5}{
            margin-left: -($i * 5) + px;
        } 
        .mr-md-m#{$i * 5}{
            margin-right:  -($i * 5) + px;
        }
    }
}
@media (min-width: $screen-lg) {
    .mt-lg-0{
        margin-top: 0px;
    } 
    .mb-lg-0{
        margin-bottom: 0px;
    }
    .ml-lg-0{
        margin-left: 0px;
    } 
    .mr-lg-0{
        margin-right: 0px;
    }
    @for $i from 1 through 32{
        .mt-lg-#{$i * 5}{
            margin-top: $i * 5 + px;
        } 
        .mb-lg-#{$i * 5}{
            margin-bottom: $i * 5 + px;
        }
        .ml-lg-#{$i * 5}{
            margin-left: $i * 5 + px;
        } 
        .mr-lg-#{$i * 5}{
            margin-right: $i * 5 + px;
        }
        .mt-lg-m#{$i * 5}{
            margin-top: -($i * 5) + px;
        } 
        .mb-lg-m#{$i * 5}{
            margin-bottom: -($i * 5) + px;
        }
        .ml-lg-m#{$i * 5}{
            margin-left: -($i * 5) + px;
        } 
        .mr-lg-m#{$i * 5}{
            margin-right: -($i * 5) + px;
        }
    }
}
