.reviews-grid{
    .reviews-item{
        margin-bottom: 20px;
        
        .reviews-content{
            color: $white;
            position: relative;    
            background: #9b9b9b;
            padding: 20px;
            margin-bottom: 25px;
            @include box-shadow($reviews-grid-box-shadow);
            font-size: $reviews-grid-font-size;
            font-weight: $reviews-grid-font-weight;
            font-style: italic;
            &:before, &:after{
                content: "";
                display: inline-block;
                position: absolute;
                left: 0;
                bottom: -20px;
            }
            &:before {
                width: 20px;
                height: 20px;
                background: #9b9b9b;
                box-shadow: inset 12px 0 13px rgba(0,0,0,0.5);
            }
            &:after {
                width: 0;
                height: 0;
                border: 10px solid transparent;
                border-bottom-color: #f7f8fa;
                border-left-color: #f7f8fa;
            }
            .reviews-title{
                font-size: $reviews-grid-font-size-title;
                font-weight: $reviews-grid-font-weight-title;
                margin-bottom: 15px;
            }
        }
        .reviews-author{
            font-size: $reviews-grid-font-size-author;
            font-weight: $reviews-grid-font-weight-author;
        }
    }
}