.pager {
    padding-left: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    @include clearfix;
    li {
        display: inline;
        > a,
        > span {
            font-size: 12px;
            display: inline-block;
            padding: 6px 12px;
            background-color: transparent;
            border: 1px solid lighten($main-color, 40%);
            border-radius: $pager-border-radius;
            color: $text-color;
            text-transform: uppercase;
            opacity: 0.75;
        }
        > a:hover,
            > a:focus {
            text-decoration: none;
            background-color: $main-color;
            color: $white;
            opacity: 1;
        }
    }

    .next {
        > a,
        > span {
            float: right;
        }
    }

    .previous {
        > a,
        > span {
            float: left;
        }
    }

    .disabled {
        > a,
        > a:hover,
            > a:focus,
            > span {
            color: $pager-disabled-color;
            background-color: $pager-bg;
            cursor: $cursor-disabled;
            visibility: hidden;
        }
    }

    @media (max-width: $screen-xs-max){  
        li {
            display: block;
            margin-bottom: 5px;
            > a,
            > span {
                display: block!important;
                float: none!important;
                text-align: center!important;
            }
        }
    }
}
#project-wrapper,
#realization-wrapper{
    .pager{
        margin-top: 20px;
    }
}
