/* GALLERY */
.no-image{
    display: block;
    width: 100%;
    height: 185px;
    background-color: #f1f2f6;
    background-image: url('../img/logo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: initial;
}
.gallery-list{
    .item{
        margin-bottom: 20px;
        .bg{
            background-color: #f5f5f5;
            .col-sm-4{
                padding-left: 0px;
                padding-right: 0px;
            }
            .col-sm-8{
                padding-right: 0px;
            }
        }
        .image{
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            height: 180px;
            width: 100%;
            display: block;
        }
        .gallery-content-wrapper{
            padding: 10px 10px 10px 0;
            .title{
                font-size: 13pt;
                font-weight: 600;
                margin: 0;
            }
            .share{
                margin-bottom: 15px;
            }

            @media (max-width: $screen-xs-max) {

                .body{
                    min-height: auto;
                    max-height: auto;
                }
            }
            .footer{
                text-align: right;
            }
        }
    }
}
.gallery {
    .thumbnail{
        padding: 0;
        background-color: transparent;
        border: 0px solid #ddd;
        border-radius: 0px;
        padding: 0px;
        position:relative;
        text-align: center;
        .img-cover{
            object-fit: cover;
            object-position: top;
            min-width: 100%;
            min-height: 200px;
        }
        .img-contain{
            object-fit: contain;
            object-position: center;
            min-width: auto;
            min-height: 200px;
        }
        a{
            position: absolute;
            z-index: 1000;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
        }
        @media (max-width: $screen-xs-max) {
            .caption-title{
                display: block !important;
            }
        }
    }
    .thumbnail_container {
        position: relative;
        width: 100%;
        padding-bottom: 75%;
        margin-bottom:10px;
        .thumbnail {
            position:absolute;
            width:100%;
            height:100%;
            img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
        img{
            max-height:100%;
            max-width:100%;
        }
    }

    &.gallery-blocks{
        .row{
            margin-left: -4px;
            margin-right: -4px;
            .item{
                padding-left: 4px;
                padding-right: 4px;
                .thumbnail{
                    outline: 0;
                    min-height: 200px;
                    max-height: 200px;
                    margin-bottom: 8px;
                    padding: 0;
                    background-color: #fff;
                    border: 0px solid #ddd;
                    border-radius: 0px;
                    padding: 0px;
                    position:relative;
                    overflow:hidden;
                    text-align: center;
                    -webkit-transform-style: preserve-3d;
                    -moz-transform-style: preserve-3d;
                    transform-style: preserve-3d;
                    @include transition(all .2s ease-in-out);
                    a{
                        position: absolute;
                        z-index: 1000;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 100%;
                        width: 100%;
                    }
                    .image{
                        outline: 0;
                        background-position: center;
                        background-size: initial;
                        background-repeat: no-repeat;
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 100%;
                        width: 100%;
                    }
                    &.img-cover{
                        overflow: hidden;
                        max-height: 200px;
                        .img-gallery{
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                    &.img-contain{
                        .img-gallery{
                            object-fit: contain;
                            object-position: center;
                        }
                    }
                }
            }
        }
    }
    &.gallery-grid{
        .row{
            margin-left: -4px;
            margin-right: -4px;
            .item{
                padding-left: 4px;
                padding-right: 4px;
                .thumbnail{
                    margin-bottom: 8px;
                    padding: 0;
                    border: 0px solid #ddd;
                    border-radius: 0px;
                    padding: 0px;
                    position:relative;
                    text-align: center;
                    &.img-cover{
                        overflow: hidden;
                        max-height: 200px;
                        min-height: 200px;
                        .img-gallery{
                            object-fit: cover;
                            object-position: center;
                            min-width: 100%;
                            min-height: 200px;
                        }
                    }
                    &.img-contain{
                        .img-gallery{
                            object-fit: contain;
                            object-position: center;
                        }
                    }
                    .overlay{
                        margin: 38px;
                        padding: 0;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 1;
                        text-align: left;
                        background-color: rgba(0,0,0,.35);
                        @include transition(all 0.2s ease-in-out);
                        opacity: 0;
                    }
                    .caption{
                        padding: 0;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 2;
                        text-align: left;
                        @include transition(all 0.3s ease-in 0.1s);
                        opacity: 0;
                        &.no-hover{
                            opacity: 1;
                        }
                        .container-fluid  {
                            display: table;
                            height: 100%;
                            width: 100%;
                            .row-fluid  {
                                display: table-cell;
                                height: 100%;
                                vertical-align: middle;
                                .col-fluid  {
                                    padding: 38px;
                                    float: none;
                                    margin: 0 auto;
                                    color: $white;
                                    p{
                                        color: inherit;
                                        font-size: 14px;
                                        &.caption-title{
                                            font-size: 26px;
                                            font-weight: 700;
                                            line-height: 1;
                                            text-transform: uppercase;
                                        }
                                    }
                                    .underline{
                                        background-color: #7d7d7d;
                                        &:after{
                                            bottom: -4px;
                                            background-color: inherit;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .image{
                    width: 100%;
                }
                &:hover, &:focus{
                    .thumbnail{
                        .overlay{
                            margin: 38px;
                            opacity: 1;
                        }
                        .caption{
                            opacity: 1;
                        }
                    }
                }

            }
        }

    }
    &.gallery-justified{
        margin: 0 -10px;
        width: calc(100% + 20px);
    }

    &.gallery-circles{
        ul {
            padding: 0;
            margin: 0 -10px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .item{
                list-style: none;
                margin: 10px;
                padding: 0 4px;
                position: relative;
                display: block;
                height: 135px;
                width: 135px;
                cursor: pointer;
                transition: transform .3s; 
                .item-wrapper{
                    position: relative;
                    .caption{
                        display: none;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 2;
                        height: 100%;
                        width: 100%;
                    }
                    a{
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 3;
                        display: block;
                        height: 100%;
                        width: 100%;
                        &:hover{
                            + .caption{
                                display: block
                            }
                        }
                    }
                    .image{
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 1;
                        display: block;
                        height: 125px;
                        width: 125px;
                        -webkit-border-radius: 50%;
                        -moz-border-radius:  50%;
                        border-radius:  50%;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        border: 2px solid $main-color;
                    }
                }
                &:hover{
                    transform: scale(1.125);
                    z-index: 100;
                }
            }
        }

        @media (min-width: 375px) {
            &{
                ul{
                    .item{
                        height: 165px;
                        width: 165px;
                        .item-wrapper{
                            .image{
                                height: 155px;
                                width: 155px;
                            }
                        }
                    }
                }
            }
        }
        @media (min-width: 425px) {
            &{
                ul{
                    .item{
                        height: 185px;
                        width: 185px;
                        .item-wrapper{
                            .image{
                                height: 175px;
                                width: 175px;
                            }
                        }
                    }
                }
            }
        }

        @media (min-width: $screen-sm) {
            &{
                ul{
                    flex-direction: row;
                    margin: 0;
                    .item{
                        margin: 10px auto;
                        height: 216px;
                        width: 216px;
                        .item-wrapper{
                            .image{
                                height: 206px;
                                width: 206px;
                            }
                        }
                    }
                }
            }
        }
        @media (min-width: $screen-md) {
            &{
                ul{
                    .item{
                        height: 220px;
                        width: 220px;
                        .item-wrapper{
                            .image{
                                height: 210px;
                                width: 210px;
                            }
                        }
                    }
                }
            }
        }
        @media (min-width: $screen-lg) {
            &{
                ul{
                    .item{
                        height: 270px;
                        width: 270px;
                        .item-wrapper{
                            .image{
                                height: 260px;
                                width: 260px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.gallery-repeated{
        ul{
            padding: 0;
            margin: 0 -4px;
            .item{
                list-style: none;
                margin: 4px 0;
                padding: 0 4px;
                position: relative;
                display: block;
                float: left;
                height: 350px;
                width: 100%;
                cursor: pointer;
                .item-wrapper{
                    position: relative;
                    height: 100%;
                    width: 100%;
                    .caption{
                        display: block;
                        position: absolute;
                        bottom: 0px;
                        left: auto;
                        right: 0;
                        z-index: 4;
                        width: 100%;
                        background-color: rgba(0,0,0,.2);
                        padding: 10px 20px;
                        text-align: right;
                        p{
                            color: $white;
                            text-shadow: $white 0 0 0;
                            font-size: 15px;
                            text-transform: uppercase;
                            margin-bottom: 0px;
                        }
                        a{
                            color: $white;
                            font-size: 15px;
                            text-transform: lowercase;
                        }
                    }
                    > a{
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 10;
                        display: block;
                        height: 100%;
                        width: 100%;
                    }
                    .image{
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 1;
                        display: block;
                        height: 100%;
                        width: 100%;
                        background-position: center top;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }
                }

                @media (min-width: $screen-sm){
                    &{
                        height: 500px;
                    }
                    &:nth-child(2n+1){
                        width: 40%;

                    }
                    &:nth-child(2n+2){
                        width: 60%;

                    }
                }
                @media (min-width: $screen-md){
                    &{
                        height: 750px;
                    }
                    &:nth-child(2n+1){
                        width: 25%;

                    }
                    &:nth-child(2n+2){
                        width: 75%;

                    }
                }
            }
        }
    }
}
/* END GALLERY*/
