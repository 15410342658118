
.mh-xs-50{
    min-height: 50px;
} 
.mh-xs-100{
    min-height: 100px;
} 
.mh-xs-150{
    min-height: 150px;
} 
.mh-xs-200{
    min-height: 200px;
}     
.mh-xs-250{
    min-height: 250px;
}     
.mh-xs-270{
    min-height: 270px;
}     
.mh-xs-280{
    min-height: 280px;
}      
.mh-xs-290{
    min-height: 290px;
}      
.mh-xs-300{
    min-height: 300px;
}     
.mh-xs-350{
    min-height: 350px;
}       
.mh-xs-360{
    min-height: 360px;
} 

@media (min-width: $screen-sm) {
    .mh-sm-50{
        min-height: 50px;
    } 
    .mh-sm-100{
        min-height: 100px;
    } 
    .mh-sm-150{
        min-height: 150px;
    } 
    .mh-sm-200{
        min-height: 200px;
    }     
    .mh-sm-250{
        min-height: 250px;
    }         
    .mh-sm-270{
        min-height: 270px;
    }     
    .mh-sm-280{
        min-height: 280px;
    }      
    .mh-sm-290{
        min-height: 290px;
    }      
    .mh-sm-300{
        min-height: 300px;
    }     
    .mh-sm-350{
        min-height: 350px;
    }     
    .mh-sm-360{
        min-height: 360px;
    } 
}
@media (min-width: $screen-md) {
    .mh-md-50{
        min-height: 50px;
    } 
    .mh-md-100{
        min-height: 100px;
    } 
    .mh-md-150{
        min-height: 150px;
    } 
    .mh-md-200{
        min-height: 200px;
    }     
    .mh-md-250{
        min-height: 250px;
    }     
    .mh-md-270{
        min-height: 270px;
    }     
    .mh-md-280{
        min-height: 280px;
    }      
    .mh-md-290{
        min-height: 290px;
    }      
    .mh-md-300{
        min-height: 300px;
    }     
    .mh-md-350{
        min-height: 350px;
    }    
    .mh-md-360{
        min-height: 360px;
    } 
}
@media (min-width: $screen-lg) {
    .mh-lg-50{
        min-height: 50px;
    } 
    .mh-lg-100{
        min-height: 100px;
    } 
    .mh-lg-150{
        min-height: 150px;
    } 
    .mh-lg-200{
        min-height: 200px;
    }     
    .mh-lg-250{
        min-height: 250px;
    }  
    .mh-lg-270{
        min-height: 270px;
    }     
    .mh-lg-280{
        min-height: 280px;
    }      
    .mh-lg-290{
        min-height: 290px;
    }      
    .mh-lg-300{
        min-height: 300px;
    }     
    .mh-lg-350{
        min-height: 350px;
    }   
    .mh-lg-360{
        min-height: 360px;
    }     

}
