#user{
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    .alert{
        border-radius: 0;
        margin-bottom: 10px;
        padding: 10px;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
    }
    .title{
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        color: #666;
        text-align: center;
    }
}
#sidebar{
    position: relative;
    .affix{
        top: 0;
        width: 20%;
    }
}
.adminbar{
    #sidebar{
        .affix{
            top: 55px;
        }
    }
}