
.hr-line{
    height: 1px;
    background-color: lighten($text-color, 50%);
    &.hr-full{
        width: 100%;
    }
    &.hr-small{
        width: 72px;
    }
}