
.max-h-xs-50{
    max-height: 50px;
    overflow: hidden;
} 
.max-h-xs-100{
    max-height: 100px;
    overflow: hidden;
} 
.max-h-xs-150{
    max-height: 150px;
    overflow: hidden;
} 
.max-h-xs-200{
    max-height: 200px;
    overflow: hidden;
}     
.max-h-xs-250{
    max-height: 250px;
    overflow: hidden;
}     
.max-h-xs-270{
    max-height: 270px;
    overflow: hidden;
}     
.max-h-xs-280{
    max-height: 280px;
    overflow: hidden;
}      
.max-h-xs-290{
    max-height: 290px;
    overflow: hidden;
}      
.max-h-xs-300{
    max-height: 300px;
    overflow: hidden;
}     
.max-h-xs-350{
    max-height: 350px;
    overflow: hidden;
}       
.max-h-xs-360{
    max-height: 360px;
    overflow: hidden;
} 

@media (min-width: $screen-sm) {
    .max-h-sm-50{
        max-height: 50px;
        overflow: hidden;
    } 
    .max-h-sm-100{
        max-height: 100px;
        overflow: hidden;
    } 
    .max-h-sm-150{
        max-height: 150px;
        overflow: hidden;
    } 
    .max-h-sm-200{
        max-height: 200px;
        overflow: hidden;
    }     
    .max-h-sm-250{
        max-height: 250px;
        overflow: hidden;
    }         
    .max-h-sm-270{
        max-height: 270px;
        overflow: hidden;
    }     
    .max-h-sm-280{
        max-height: 280px;
        overflow: hidden;
    }      
    .max-h-sm-290{
        max-height: 290px;
        overflow: hidden;
    }      
    .max-h-sm-300{
        max-height: 300px;
        overflow: hidden;
    }     
    .max-h-sm-350{
        max-height: 350px;
        overflow: hidden;
    }     
    .max-h-sm-360{
        max-height: 360px;
        overflow: hidden;
    } 
}
@media (min-width: $screen-md) {
    .max-h-md-50{
        max-height: 50px;
        overflow: hidden;
    } 
    .max-h-md-100{
        max-height: 100px;
        overflow: hidden;
    } 
    .max-h-md-150{
        max-height: 150px;
        overflow: hidden;
    } 
    .max-h-md-200{
        max-height: 200px;
        overflow: hidden;
    }     
    .max-h-md-250{
        max-height: 250px;
        overflow: hidden;
    }     

    .max-h-md-270{
        max-height: 270px;
        overflow: hidden;
    }     
    .max-h-md-280{
        max-height: 280px;
        overflow: hidden;
    }      
    .max-h-md-290{
        max-height: 290px;
        overflow: hidden;
    }      
    .max-h-md-300{
        max-height: 300px;
        overflow: hidden;
    }     
    .max-h-md-350{
        max-height: 350px;
        overflow: hidden;
    }    
    .max-h-md-360{
        max-height: 360px;
        overflow: hidden;
    } 
}
@media (min-width: $screen-lg) {
    .max-h-lg-50{
        max-height: 50px;
        overflow: hidden;
    } 
    .max-h-lg-100{
        max-height: 100px;
        overflow: hidden;
    } 
    .max-h-lg-150{
        max-height: 150px;
        overflow: hidden;
    } 
    .max-h-lg-200{
        max-height: 200px;
        overflow: hidden;
    }     
    .max-h-lg-250{
        max-height: 250px;
        overflow: hidden;
    }     

    .max-h-lg-270{
        max-height: 270px;
        overflow: hidden;
    }     
    .max-h-lg-280{
        max-height: 280px;
        overflow: hidden;
    }      
    .max-h-lg-290{
        max-height: 290px;
        overflow: hidden;
    }      
    .max-h-lg-300{
        max-height: 300px;
        overflow: hidden;
    }     
    .max-h-lg-350{
        max-height: 350px;
        overflow: hidden;
    }   
    .max-h-lg-360{
        max-height: 360px;
        overflow: hidden;
    }     

}
