// Import variables
@import "userVariables";
// Import avatars
@import "userAvatars";
// Import layout
#user{
    padding-top: 120px;
    @import "layout/userContent";
    @import "layout/userForms";
    @import "layout/userOverall";
    @import "layout/userSidebar";
    @import "layout/userTab";
}