form{
    label{                            
        font-size: 13px;
        margin: 0 0 5px;
        font-weight: 300;
        text-transform: uppercase;
        color: $text-color;
    } 
    .form-control{
        color: $text-color;
        border: 0;
        background-color: $white;    
        -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
        -moz-box-shadow:    0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
        box-shadow:         0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);     
    }
    .btn{         
        font-size: 13px;
        font-weight: 300;
        text-transform: uppercase;
        text-align: center;
        -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
        -moz-box-shadow:    0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
        box-shadow:         0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);  
    }
}