.priceTable{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 20px;
        margin-bottom: 1em;
        background-color: #fff;
        @include box-shadow(0 0 18px 0 rgba(40,20,70,0.1));
        @include transition(all 0.35s ease-out);

        &:hover{ 
            z-index: 1;
            @include box-shadow(0 0 18px 0 rgba(40,20,70,0.3));
        }
        &:last-of-type{ 
            margin-right: 0;
        }
        li{
            padding: 15px 15px 15px 45px;
            position: relative;
            border-bottom: 1px solid #eee;
            list-style: none;
            font-size: 13px;
            &:before{
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);
                content: "\f14a";
                font-family: "FontAwesome";
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing:antialiased;
                width: 1em;
                display: inline-block;
                color: lighten(#000, 15%);
            }
            &.priceHeader{
                font-size: 15px;
                padding: 15px;
                text-align: center;
                text-transform: uppercase;
                &:before{
                    content: '';
                }
            }
            &.priceFooter{
                padding: 15px;
                text-align: center;
                margin-top: auto;
                border-bottom: 0;
                text-transform: uppercase;
                .btn{
                    color: #fff!important;
                }
                &:before{
                    content: '';
                }
            }
        }
    }
} 