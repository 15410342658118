$reviews-carousel-bg: #fff;
$reviews-carousel-box-shadow: 0 0 18px 0 rgba(40,20,70,0.1); 
.reviews-carousel{
    .carousel{
        .carousel-inner{ 
            > .item{
                margin: 20px;
                .carousel-description{
                    background-color: $reviews-carousel-bg;
                    @include box-shadow($reviews-carousel-box-shadow);
                    padding: 20px 20px 40px;
                    height: 260px;
                    overflow: hidden;
                    text-align: left;
                    font-weight: 400;
                    font-size: 18px;
                    text-transform: initial;
                    text-shadow: none;
                    line-height: 1.3;
                    letter-spacing: 0px;
                    color: $main-color;
                    h1,h2,h3,h4,h5,h6{                        
                        color: $main-color;
                        text-shadow: none;
                        text-transform: initial;
                    }
                }
            }
        }
        .carousel-control{
            &.left{
                left: -70px;
            }
            &.right{
                right: -70px;
            }
        }
        .carousel-indicators {
            bottom: -30px;
            li{
                border-color: $text-color;
                &.active {
                    background-color: $text-color;
                    border-color: $text-color;

                }
            }
        }
    }
}