.modal-open{
    padding-right: 0px !important;
    overflow: hidden;
    .alert-cookie,
    .navbar,
    .containers,
    header,
    footer,
    #project_manager{
        @include filter(blur, 2px);
    }
}
.modal{
    &.wicode-modal{
        .btn{
            box-shadow: none; 
        }
        .hidden-modal{
            display: none;
        }
        .modal-dialog{
            .modal-content{   
                border: 1px solid rgba(0, 0, 0, 0.2);
                border-radius: 0px;
                box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
                background-color: #fff;
                color: $text-color;    
                .modal-header{
                    padding: 20px 15px 20px;
                    background: #f9f9f9;
                    border-color: transparent;
                    z-index: 1;
                    position: relative;
                    .fa{
                        color: $text-color;
                        margin-right: 10px;
                    }
                    .modal-title{
                        margin-bottom:5px;
                        letter-spacing: 0px;
                        text-transform: uppercase;
                        font-weight: 400;
                        color: $text-color;
                        font-size: 18px;
                        line-height: 1.2;
                    }
                    .modal-info{
                        margin-top:0px;
                        margin-bottom:0px;
                        font-size: 13px;
                        font-weight: 300;
                        text-transform: uppercase;
                        color: $text-color;
                    }
                    .close{
                        opacity: 0.6;
                        font-size: 40px;
                        position: absolute;
                        top:-5px;
                        right: 10px;
                        font-weight: 300;
                        text-shadow: none;
                        color: $text-color;
                    }
                }

                .modal-body{
                    background-color: #f9f9f9;
                    padding-top: 20px;
                    padding-bottom: 10px;
                    &.modal-media-view-body{
                        padding: 0;
                    }
                    .comments-login{                        
                        text-transform: uppercase;
                    }                    
                    label.btn{
                        margin: 0;
                        padding: 0;
                    }
                    .btn{
                        .checkbox{
                            margin: 0;
                            line-height: 1;
                            input[type="checkbox"], input[type="radio"]{
                                margin: 0;
                                left: 10px;
                                top: 5px;
                            }
                            label{
                                margin: 0;
                                padding: 8px 20px 4px 36px;
                                font-size: 11px;
                            }
                        }
                    }
                    .modal-media-element{
                        text-align: center;                        
                        img{
                            margin: 0 auto;
                        }
                        .icon-group{
                            padding: 40px 0;
                        }
                    }
                    #comments_area{
                        padding: 0 10px;
                        #comments-wrapper{
                            .comments-header{
                                background-color: #fff;
                                margin-left: -25px;
                                margin-right: -25px;
                            }
                        }
                        #comments-messages{
                            #comments-messages-inner{
                                &:last-child{
                                    > .comments{
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                    .form-group{
                        text-align: center;
                        .btn{
                            margin-top: 30px;
                        }
                        .bootstrap-select{
                            .btn{
                                background-color: #fff;
                                padding: 6px 25px 6px 10px!important;
                                margin-top: 0;
                                font-size: 12px;                                
                                border: 1px;
                                color: $text-color;
                                font-weight: 300;
                            }
                            &.btn-group{
                                .dropdown-menu{
                                    li{
                                        a{
                                            color: $text-color;
                                            font-size: 13px;     
                                            font-weight: 300;
                                        }
                                        &.selected{
                                            a{
                                                color: #fff;
                                                font-weight: 400;
                                                background-color: $main-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .slider{
                            margin-top: 5px;
                            width: 100%;
                            display: block;
                            .slider-selection{
                                box-shadow: none;
                                background-image: none;
                                background-color: darken(#42454A, 10%);
                                border-radius: 3px;
                            }
                            .slider-handle{
                                background-image: none;
                                background-color: lighten($main-color, 10%);
                            }
                            .slider-track-high, .slider-track-low{

                                background-color: #ccc;
                            }
                        }
                        .note-editor{
                            text-align: left;
                            color: $text-color;
                            p{
                                text-transform: initial;
                                text-align: left;
                                color: $text-color;

                            }
                            .btn{
                                margin-top: 0px;
                            }
                        }
                    }
                    label{                            
                        font-size: 13px;
                        margin: 0 0 5px;
                        font-weight: 300;
                        text-transform: uppercase;
                        color: $text-color;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .form-control{
                        color: $text-color;
                        border: 0;
                        background-color: $white;
                        box-shadow: bottom-shadow(1), top-shadow(1);  
                    }
                    .cr-icon{
                        color: $text-color;
                    }
                    p{
                        text-transform: uppercase;
                        font-size: 13px;
                        margin: 0;
                        color: $text-color;
                        &.or{
                            font-size: 18px;
                            font-weight: 200;
                            margin:22px 0;
                            color: $text-color;
                        }
                    }
                }
                .modal-footer{
                    border-width: 0;                    
                    color: $text-color;
                    background: #f9f9f9;
                    text-align: center;           
                    text-transform: uppercase;
                    padding-top: 20px;
                    padding-bottom: 20px;  
                    .btn{
                        text-transform: uppercase;
                        font-weight: 300;
                        padding-left: 20px;
                        padding-right: 20px;
                        font-size: 12px;
                    }
                }

                @media (min-width: $screen-sm) { 
                    .modal-header{
                        .modal-title{
                            font-size: 22px;
                            font-weight: 400;
                        }
                        .modal-info{
                            font-size: 15px;
                            font-weight: 200;
                        }
                        .close{
                            top: 0;
                        }
                    }

                    .modal-body{
                        padding-top: 35px;
                        padding-bottom: 35px;
                    }

                }

            }
        }     
        &#login_modal,
        &#register_modal{
            .modal-dialog{
                .modal-content{   
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    border-radius: 0px;
                    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
                    text-align: center;  
                    label{                            
                        font-size: 13px;
                        margin: 0 0 5px;
                        font-weight: 400;
                        text-transform: uppercase;
                    }
                    p{
                        text-transform: uppercase;
                        font-size: 13px;
                        margin: 0;
                        &.or{
                            font-size: 18px;
                            font-weight: 300;
                            margin:22px 0;
                        }
                    }       
                    .modal-header{
                        padding: 30px 15px 20px;
                        background: #f9f9f9;
                        border-color: transparent;
                        z-index: 1;
                        position: relative;
                        .modal-title{
                            margin-bottom:10px;
                            letter-spacing: 0px;
                            text-transform: uppercase;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 1.2;
                        }
                        .modal-info{
                            margin-top:0;
                            margin-bottom:0;
                            font-size: 15px;
                            font-weight: 300;
                            text-transform: uppercase;
                        }
                        .close{
                            opacity: 0.6;
                            font-size: 40px;
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            font-weight: 300;
                            text-shadow: none;
                        }
                    }

                    .modal-body{
                        background-color: transparent;
                        padding-top: 20px;
                        padding-bottom: 20px;                        
                        .social {
                            margin-top: 10px;
                            ul{
                                li{
                                    float: none;
                                }
                            }
                        }                        
                        .btn{
                            text-transform: uppercase;
                            font-weight: 300;
                            padding: 9px 20px;
                            color: #fff;
                            background-color: darken(#42454A, 5%);
                            border-color: darken(#42454A, 5%);
                            &:hover, &:focus{
                                background-color: #42454A;
                                border-color: #42454A;
                            }
                        }
                        .checkbox{
                            margin-top: 30px;
                            text-align: left;
                            label{
                                font-weight: 400;
                            }

                        }
                        .form-group{
                            &.last{
                                margin-top: 20px;
                                margin-bottom: 0px;
                                text-align: left;
                                p{
                                    font-size: 12px;
                                    font-weight: 300;
                                    a{
                                        font-weight: 400;
                                    }
                                }
                            }
                            .btn{
                                margin-top: 15px;
                            }
                            .bootstrap-select{
                                .btn{
                                    background-color: darken(#42454A, 10%);
                                    padding: 6px 25px 6px 10px!important;
                                    margin-top: 0;
                                    font-size: 12px;                                
                                    border: 1px;
                                }
                            }
                        }
                    }
                    .modal-footer{
                        border-width: 0;                    
                        color: $text-color;
                        background: #f5f5f5;
                        text-align: center;           
                        text-transform: uppercase;
                        padding-top: 20px;
                        padding-bottom: 20px;
                        .social {
                            margin-top: 10px;
                            ul{
                                li{
                                    float: none;
                                }
                            }
                        }
                        .btn{
                            text-transform: uppercase;
                            font-weight: 300;
                            padding-left: 20px;
                            padding-right: 20px;
                            font-size: 12px;
                        }

                        p{
                            &.or{
                                margin:0 0 22px;
                            }
                        }
                    }


                    @media (min-width: $screen-sm) { 
                        .modal-header{
                            .modal-title{
                                font-size: 26px;
                            }
                            .modal-info{
                                font-size: 15px;
                            }
                        }
                        .modal-body{
                            padding-top: 35px;
                            padding-bottom: 35px;
                        }

                    }
                }
            }   
        }
        &#view_media{
            .modal-dialog{
                height: 100%;
                width: 100%;
                margin: 0 auto;  
                .view-left{     
                    padding: 0 20px;
                    position: relative;
                    .media-pagin{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        color: #fff;
                        font-size: 44px;
                        width: 20px;
                        text-align: center;
                        opacity: 0.7;
                        &:hover,&:focus{
                            opacity: 1;
                        }
                        &.media-left{
                            left: 1px;
                        }
                        &.media-right{
                            right: 1px;
                        } 

                    }
                    .view-body{
                        .box{
                            display: block;
                            img{
                                margin: 20px auto;
                                box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
                            }
                        }
                    }
                }
                .view-right{
                    background-color: $white;
                    .view-header{
                        color: $text-color;
                        background: #fff;        
                        text-transform: uppercase;
                        padding: 20px;
                        .modal-title{
                            font-size: 20px;
                            color: $text-color;
                            margin: 0;
                        }
                        .modal-info{
                            font-size: 16px;
                            color: $text-color;
                            margin: 0;
                        }
                    }
                    #comments_area{
                        #comments-wrapper{
                            .comments-header{
                                padding: 20px 15px;
                                margin: 0;
                                h2{
                                    font-size: 16px;
                                }
                                .btn{
                                    padding: 4px 10px;
                                }
                            }
                            #comments-messages{
                                padding: 20px;
                                position: relative;
                            }
                        }
                    }
                    .view-footer{
                        border-width: 0;                    
                        color: $text-color;
                        background: #f9f9f9;
                        text-align: center;           
                        text-transform: uppercase;
                        padding-top: 20px;
                        padding-bottom: 20px;
                        .btn{
                            text-transform: uppercase;
                            font-weight: 300;
                            padding-left: 20px;
                            padding-right: 20px;
                            font-size: 12px;
                        }
                    }
                }
                @media (min-width: $screen-sm) { 
                    .view-left{
                        width: calc(100% - 320px);
                        height: 100%;
                        float: left;
                        position: relative;
                        .media-pagin{
                            &.media-left{
                                left: 3px;
                            }
                            &.media-right{
                                right: 3px;
                            }   
                        }
                        .view-body{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: calc(100% - 60px);
                            transform: translate(-50%, -50%);
                            .box{
                                img{
                                    margin: 0 auto;
                                    max-height: 95vh;
                                }
                            }
                        }
                    }
                    .view-right{
                        width: 320px; 
                        height: 100%;
                        float: right;
                        position: relative;
                        padding-bottom: 71.15px;
                        #comments-messages{
                            overflow-y: auto;
                            height: calc(100vh - 218.15px);
                        }
                        .view-footer{
                            position: absolute;
                            bottom: 0;
                            z-index: 1;
                            left: 0;
                            right: 0;
                        }
                    }
                }
                @media (min-width: $screen-md) { 
                    .view-left{
                        width: calc(100% - 400px);                        
                    }
                    .view-right{
                        width: 400px;                         
                    }
                }
            }   
        }
        &#login_modal{
            .modal-content{  
                .modal-body{
                    .checkbox,
                    .form-group{
                        margin-left: 10%;
                        margin-right: 10%;
                    }
                    @media (min-width: $screen-sm) { 
                        .checkbox,
                        .form-group{
                            margin-left: 20%;
                            margin-right: 20%;
                        }
                    }
                }
            }

        }
        &#chat_delete_confirmation{
            .modal-content{  
                .modal-body{
                    text-align: left;
                }
            }            
        }
        &.modal-media{
            .modal-dialog{
                .modal-body{
                    #comments_area{
                        #comments-wrapper{
                            .comments-header{
                                margin-left: -10px;
                                margin-right: -10px;
                            }
                        }
                    }
                }
            }
        }
        &.modal-upload{
            #actions{
                .btn-group{
                    .glyphicon,
                    .fa{
                        margin-right: 10px;
                    }
                }
            }
            .dropdown-menu{
                padding: 0;
                > li{
                    > a{
                        font-weight: 300;
                        padding: 3px 12px;
                        font-size: 13px;
                        .glyphicon,
                        .fa{
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
    @media (min-width: $screen-sm) { 
        &.modal-center{
            text-align: center;
            padding: 0!important;
            &:before {
                content: '';
                display: inline-block;
                height: 100%;
                vertical-align: middle;
                margin-right: -4px;
            }
            .modal-dialog {
                display: inline-block;
                text-align: left;
                vertical-align: middle;
            }

        }
    }
}
