@mixin avatar($size) {
    width: $size;
    height: $size;
    background-color: transparent;
    text-align: center;
    display: inline-block;
    .initials {
        position: relative;
        top: $size*0.20;
        font-size: $size*0.43;
        line-height: $size*0.43;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
    }
    img{
        //max-width: $size;
        //max-height: $size; 
        width: $size;
        height: $size; 
        object-fit: cover;
        object-position: center;
    }
}