@mixin button-wicode-variant($color, $color-hover, $background, $background-hover, $border, $border-hover) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color-hover;
    background-color: $background-hover;
        border-color: $border-hover;
  }
  &:hover {
    color: $color-hover;
    background-color: $background-hover;
        border-color: $border-hover;
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color-hover;
    background-color: $background-hover;
        border-color: $border-hover;

    &:hover,
    &:focus,
    &.focus {
      color: $color-hover;
      background-color: $background-hover;
          border-color: $border-hover;
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
          border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}
