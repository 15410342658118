// Import functions
@import "functions";
// Import mixin
@import "mixin";
// Import variables
@import "reviewsVariables";
// Import layout
.reviews{
    @import "reviewsOverall";
    @import "reviewsCarousel";
    @import "reviewsGrid";
    @import "reviewsList";
}