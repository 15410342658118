#alerts-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999999;
    .alert{
        text-align: center;
        font-size: 13px;
        width: 100%;
        max-width: 1140px;
        margin: 10px auto;
        strong{
            display: block;
            text-transform: uppercase;
        }
    }
}
.adminbar{
    #alerts-wrapper{
        top: 55px;
    }
}