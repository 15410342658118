#breadcrumbs{
    font-size: 13px;
    color: #777777;
    text-align: left;
    .breadcrumb{
        padding: 5px 0;
        margin: 0 0 0 10px;
        background-color: transparent;
        border-radius: 0;
        display: inline-block;
        li{
            a{
                color: #777777;
                &:hover{
                    color: #444444;
                }
                .fa {
                    margin-right: 5px;
                }
            }
            &.active{
                a{
                    color: #444444;
                }
            }
        }
    }
    @media (min-width: 768px) {
        text-align: right;
        position: absolute;
        bottom: 30px;
        right: 20px;
    }
}