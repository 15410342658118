.bg-white{
    background-color: #ffffff;
    color: $text-color;
}
.hover-bg-white{
    @include transition(all 1s ease-out 0s);
    &:hover{
        background-color: #ffffff;
        color: $text-color;
    }
}
.bg-light-gray{
    background-color: lighten(#f1f2f6, 2%);
    color: $text-color;
}
.hover-bg-light-gray{
    @include transition(all 1s ease-out 0s);
    &:hover{
        background-color: #f1f2f6;
        color: $text-color;
    }
}
.bg-dark-gray{
    background-color: lighten(#000000, 8%);
    color: darken(#ffffff, 8%);
    .underline{
        &:after{
            background-color: darken(#ffffff, 5%);
        }
    }

    .header{
        h1,h2,h3,h4,h5,h6{
            color: darken(#ffffff, 8%);
            small{
                color: darken(#ffffff, 8%);
            }
        }
    }
    a:not(.btn){
        color: darken(#ffffff, 8%);
        &:hover, &:focus{
            color: darken(#ffffff, 20%);
        }
    }
}
.hover-bg-dark-gray{
    @include transition(all 1s ease-out 0s);
    &:hover{
        background-color: lighten(#000000, 8%);
        color: #fff;
        .underline{
            &:after{
                background-color: $main-color;
            }
        }

        .header{
            h1,h2,h3,h4,h5,h6{
                color: #fff!important;
                small{
                    color: #fff!important;
                }
            }
        }
    }
}
.bg-gray{
    background-color: #a0a0a0;
    color: $text-color;
}
.hover-bg-gray{
    @include transition(all 1s ease-out 0s);
    &:hover{
        background-color: #a0a0a0;
        color: $text-color;
    }
}
.bg-black{ 
    color: darken(#ffffff, 5%);
    background-color: #000000;

    .header{
        h1,h2,h3,h4,h5,h6{
            color: #fff!important;
            small{
                color: #fff!important;
            }
        }
    }
    a:not(.btn){
        color: darken(#ffffff, 5%);
        &:hover, &:focus{
            color: darken(#ffffff, 15%);
        }
    }
}
.hover-bg-black{
    @include transition(all 1s ease-out 0s);
    &:hover{
        color: #ffffff;
        background-color: #000000;

        .header{
            h1,h2,h3,h4,h5,h6{
                color: #fff!important;
                small{
                    color: #fff!important;
                }
            }
        }
    }
}
.bg-main{
    color: $text-color;
    background-color: $main-color;

    .header{
        h1,h2,h3,h4,h5,h6{
            color: #fff!important;
            small{
                color: #fff!important;
            }
        }
    }
    .underline{
        &:after{
            background-color: #FFF;
        }
    }
}
.hover-bg-main{
    @include transition(all 1s ease-out 0s);
    &:hover{
        color: $text-color;
        background-color: $main-color;

        .header{
            h1,h2,h3,h4,h5,h6{
                color: #fff!important;
                small{
                    color: #fff!important;
                }
            }
        }
        .underline{
            &:after{
                background-color: #FFF;
            }
        }
    }
}
