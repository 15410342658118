/* Import Fonts */
@import "frontendFonts";
/* Import functions */
@import "functions";
/* Import mixin */
@import "mixin"; 
/* Import variables */
@import "frontendVariables";
/* Import libs */
//@import "bootstrap";

// Core variables and mixins
//@import "bootstrap/variables";
@import "bootstrap/mixins";

// Reset and dependencies
@import "bootstrap/normalize";
@import "bootstrap/print";
//@import "bootstrap/glyphicons";

// Core CSS
@import "bootstrap/scaffolding";
@import "bootstrap/type";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";

// Components
@import "bootstrap/component-animations";
@import "bootstrap/dropdowns";
@import "bootstrap/button-groups";
@import "bootstrap/input-groups";
@import "bootstrap/navs";
@import "bootstrap/navbar";
@import "bootstrap/breadcrumbs";
@import "bootstrap/pagination";
@import "bootstrap/pager";
@import "bootstrap/labels";
@import "bootstrap/badges";
//@import "bootstrap/jumbotron";
//@import "bootstrap/thumbnails";
@import "bootstrap/alerts";
@import "bootstrap/progress-bars";
//@import "bootstrap/media";
//@import "bootstrap/list-group";
@import "bootstrap/panels";
@import "bootstrap/responsive-embed";
//@import "bootstrap/wells";
@import "bootstrap/close";

// Components w/ JavaScript
@import "bootstrap/modals";
@import "bootstrap/tooltip";
@import "bootstrap/popovers";
@import "bootstrap/carousel";

// Utility classes
@import "bootstrap/utilities";
@import "bootstrap/responsive-utilities";

@import "frontendFontawesome";
/*@import "hover";*/
/*@import "plyr"; */
/* Import layout */
@import "wiLayout";
@import "wiNavbars";
@import "wiImages";
/*@import "wiAttributes";*/
/*@import "wiAnimation";*/
@import "wiCarousel";
/*@import "wiChat"; */
/*@import "wiComments"; */
/*@import "wiEditor"; */

@import "wiBookmarks";
@import "wiBlog";
/*@import "wiNews"; 
@import "wiProducts"; */
@import "wiProjects";
@import "wiRealizations";
@import "wiCookie";
@import "wiForms";
/*@import "wiMedia";*/
@import "wiPagination";
@import "wiReviews";
@import "wiQa";
@import "wiBreadcrumbs";
@import "wiLoader";
@import "wiMaps";
@import "wiModal";
@import "wiAdminbar";
@import "wiAlerts";
@import "wiUser";
@import "lightgallery";
@import "justifiedGallery";
@import "animate";
.lg-backdrop {
    background-color: rgba(#000, .75);
}